(function () {
  angular.module('kmi.lms.user.notifications').component('userNotificationsDropDownList', {
    template: require('ajs/modules/user/notifications/grouped/notifications-list.html').default,
    controller: UserNotificationsDropDownListController,
    controllerAs: 'vm',
    bindings: {
      notifications: '=',
      onView: '&',
    },
  });

  /* @ngInject */
  function UserNotificationsDropDownListController(
    $scope,
    backUrlService,
    globalConfig,
    currentUser,
    userNotificationsService,
    siteSettingService,
    userEmailOptInService,
    courseQuizTypes,
    _,
  ) {
    const vm = this;

    vm.siteSettings = siteSettingService.getSiteSetting();
    var isDemo = globalConfig.projectName === 'demo';
    vm.unfinishedEvaluationsState = isDemo
      ? 'main.learning({view:"all", statuses: "completed", card: "incomplete"})'
      : 'main.learning({view:"all", statuses: "pending_evaluation", card: "incomplete"})';
    vm.showOptInModal = showOptInModal;

    vm.profileIncomplete = false;
    vm.newRecommendations = false;
    vm.pendingCourseCompletions = false;
    vm.upcomingEvents = false;
    vm.unfinishedEvaluations = false;
    vm.userNetworkActivities = false;
    vm.unfinishedAssessments = false;
    vm.unfinishedPreAssessments = false;
    vm.userAchievementsAchieved = false;
    vm.userAchievementsAvailable = false;
    vm.mandatoryTrainings = false;
    vm.passwordExpirationWarning = false;
    vm.registrationExpiration = false;
    vm.dueDate = false;
    vm.targetDate = false;
    vm.emailOptIn = false;
    vm.user = currentUser.get();

    vm.backUrlParam = backUrlService.external.getBackUrlParam();
    vm.markAsViewed = markAsViewed;
    vm.$onInit = activate;

    function activate() {
      $scope.$watch(
        'vm.notifications',
        function () {
          calculateNotifications();
        },
        true,
      );

      vm.courseQuizTypes = courseQuizTypes;
    }

    function calculateNotifications() {
      vm.profileIncomplete = _.has(vm.notifications, 'profileIncomplete');
      vm.newRecommendations = _.has(vm.notifications, 'newRecommendations');
      vm.pendingCourseCompletions = _.has(vm.notifications, 'pendingCourseCompletions');
      vm.upcomingEvents = _.has(vm.notifications, 'upcomingEvents');
      vm.unfinishedEvaluations = _.has(vm.notifications, 'unfinishedEvaluations');
      vm.userNetworkActivities = _.has(vm.notifications, 'userNetworkActivities');
      vm.unfinishedAssessments = _.has(vm.notifications, 'unfinishedAssessments');
      vm.unfinishedPreAssessments = _.has(vm.notifications, 'unfinishedPreAssessments');
      vm.userAchievementsAchieved = _.has(vm.notifications, 'userAchievementsAchieved');
      vm.userAchievementsAvailable = _.has(vm.notifications, 'userAchievementsAvailable');
      vm.mandatoryTrainings = _.has(vm.notifications, 'mandatoryTrainings');
      vm.passwordExpirationWarning = _.has(vm.notifications, 'passwordExpirationWarning');
      vm.registrationExpiration = _.has(vm.notifications, 'registrationExpiration');
      vm.dueDate = _.has(vm.notifications, 'dueDate');
      vm.targetDate = _.has(vm.notifications, 'targetDate');
      vm.emailOptIn = _.has(vm.notifications, 'emailOptIn');
    }

    function markAsViewed(notification) {
      userNotificationsService.excludeNotification({ type: notification });

      if (angular.isFunction(vm.onFinish)) {
        vm.onView();
      }
    }

    function showOptInModal(notification) {
      userEmailOptInService.showOptInModal(currentUser.get().email).then(function () {
        markAsViewed(notification);
      });
    }
  }
})();
