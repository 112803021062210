(function () {
  angular.module('kmi.lms.user.components').component('selfRegistrationGroupsSelection', {
    template: require('ajs/modules/user/edit/components/self-registration-groups-selection.html').default,
    controller: SelfRegistrationGroupsSelection,
    controllerAs: 'vm',
    bindings: {
      groups: '<',
      groupsChangeSubject: '<',
      loading: '=?',
      disabled: '=?',
    },
  });

  /* @ngInject */
  function SelfRegistrationGroupsSelection(Group, groupService, _) {
    var vm = this;

    vm.$onInit = onInit;
    vm.selectedGroupCategories = {};
    vm.selectGroup = selectGroup;

    function onInit() {
      bindSelfRegGroups();
      vm.disabled = true;
      vm.groups = vm.groups || [];
    }

    function bindSelfRegGroups() {
      vm.loading = true;
      groupService
        .getDefaultSelfRegGroups()
        .then(function (dfGroups) {
          if (dfGroups.length) {
            return Group.query({
              query: {
                parent_ids: dfGroups.map((g) => g.group.id),
                selfReg: true,
                corporate_license_code: null,
              },
            }).$promise.then(function (groups) {
              vm.disabled = !groups.items.length;
              vm.availableGroups = groups;
              populateGroupSelection(groups);
              bindSelectedGroups(groups);
              vm.loading = false;
            });
          }
        })
        .finally(() => {
          vm.loading = false;
        });
    }

    function bindSelectedGroups(groups) {
      const assignedGroupIds: number[] = _.map(vm.groups, 'id');

      _.forEach(groups.items, function (g) {
        if (_.includes(assignedGroupIds, g.id)) {
          g.selected = true;
        }
      });
    }

    function populateGroupSelection(groups) {
      vm.groupsByCategories = _.reduce(
        _.sortBy(groups.items, 'name'),
        function (result, group) {
          result[group.categoryId] = result[group.categoryId] || { name: group.category.name, groups: [] };
          result[group.categoryId].groups.push(group);
          return result;
        },
        {},
      );
    }

    function selectGroup(group) {
      group.selected = !group.selected;
      if (group.selected) {
        vm.groups = [...vm.groups, group];
      } else {
        vm.groups = vm.groups.filter((g) => g.id !== group.id);
      }
      vm.groupsChangeSubject?.next(vm.groups);
    }
  }
})();
