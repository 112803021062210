(function () {
  angular.module('kmi.lms.user.learning').component('editExternalCourseComponent', {
    template: require('ajs/modules/user/learning/external/edit/edit-external-course.html').default,
    controller: EditExternalCourseController,
    controllerAs: 'vm',
    bindings: {
      course: '=',
    },
  });

  /* @ngInject */
  function EditExternalCourseController(
    $uibModal,
    notificationService,
    courseService,
    backUrlService,
    moment,
    _,
    elms,
  ) {
    var vm = this;
    vm.$onInit = onInit;

    vm.backUrl = backUrlService;
    vm.forms = {
      submitted: false,
    };

    vm.submit = submit;
    vm.removeCourse = removeCourse;

    function onInit() {
      formatCreditAmount();

      // Get available course formats list
      courseService.getCourseOptions().then(function (options) {
        vm.courseOptions = options;
        vm.creditTypes = options.creditTypes;

        vm.creditTypes.push({ id: null, name: 'None' });

        bindCourseData();
      });
    }

    function bindCourseData() {
      if (vm.course.format) {
        vm.course.format = _.find(vm.courseOptions.formats, function (format) {
          return format.id === vm.course.format.id;
        });
      }

      if (vm.course.creditType || vm.course.id) {
        vm.course.creditType = _.find(vm.creditTypes, function (credit) {
          return credit.id === (_.get(vm.course.creditType, 'id') || null);
        });
      }
    }

    function submit() {
      vm.forms.submitted = true;

      if (vm.editCourseForm.$valid) {
        var courseDraft = angular.copy(vm.course);
        courseDraft.startDate = courseDraft.startDate ? moment(courseDraft.startDate).format('YYYY-MM-DD') : null;
        courseDraft.completionDate = courseDraft.completionDate
          ? moment(courseDraft.completionDate).format('YYYY-MM-DD')
          : null;
        if (courseDraft.creditType && courseDraft.creditType.id === null) {
          courseDraft.creditAmount = 0;
        }

        if (courseDraft.id) {
          vm.savePromise = courseDraft.$update();
        } else {
          vm.savePromise = courseDraft.$save();
        }

        vm.savePromise
          .then(function handleResponse() {
            angular.extend(vm.course, courseDraft);
            formatCreditAmount();
            bindCourseData();

            notificationService.info('Your changes have been saved.', 3e3);
          }, handleError)
          .finally(function () {
            vm.savePromise = null;
            vm.editCourseForm.$setPristine();
          });
      } else {
        notificationService.hideInfo();
        notificationService.error('Please fill in all required fields', 2e3);
      }

      function handleError(reason) {
        notificationService.hideInfo();
        if (_.includes([400, 403], reason.status)) {
          notificationService.error(reason.data);
        } else {
          notificationService.error('An error occurred saving course data. Please try again later.', 5e3);
        }
      }
    }

    function removeCourse() {
      var modalInstance = $uibModal.open({
        component: 'deleteExternalCourseModalComponent',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          course: function () {
            return vm.course;
          },
        },
      });

      modalInstance.result.then(redirectBack);
    }

    function redirectBack() {
      backUrlService.goBack();
    }

    function formatCreditAmount() {
      if (_.get(vm.course, 'creditAmount', false)) {
        vm.course.creditAmount = parseFloat(elms.formatDigit(vm.course.creditAmount, 3));
      }
    }
  }
})();
