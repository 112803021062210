(function () {
  angular.module('kmi.lms.user.common').component('userRegistrationCodeInput', {
    template: require('ajs/modules/user/components/registration-code-input.html').default,
    controller: UserRegistrationCodeInputController,
    controllerAs: 'vm',
    bindings: {
      user: '=',
      formSubmitted: '=',
    },
  });

  function UserRegistrationCodeInputController(notificationService) {
    const vm = this;

    vm.codeChanged = codeChanged;
    function codeChanged() {
      vm.userCorpCodeForm.corporateLicenseCode.$setValidity('code', true);
      notificationService.hideError();
    }
  }
})();
