// Module
var code = `<div class="username-avatar username-set{{vm.setValue}}" ng-attr-title="{{::!vm.hideTitle && (vm.user.title || vm.user.firstName) ? (vm.user.title || vm.user.firstName) +'\\'s avatar' : null}}" ng-class="{'is-transparent': vm.isTransparent}" ng-style="{
      'background-image': vm.backgroundImageUrl,
      'background-color':  vm.backgroundColor,
      'width': vm.size,
      'height': vm.size,
      'line-height': vm.size+'px',
      'font-size' : (vm.size <= 48) ? 'inherit' : '24px'
      }"> <span class="username-initials">{{vm.initials}}</span> </div> `;
// Exports
export default code;