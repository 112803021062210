(function () {
  angular.module('kmi.lms.trainingCategory').factory('TrainingCategory', trainingCategory);

  /* @ngInject */
  function trainingCategory($resource) {
    return $resource(
      '/a/training-category/:id/',
      { id: '@id' },
      { update: { method: 'PUT' } },
      {
        delete: {
          method: 'DELETE',
        },
      },
      { stripTrailingSlashes: false },
    );
  }
})();
