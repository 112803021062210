(function () {
  angular
    .module('kmi.lms.user.certificates')
    .constant('certificateFileMaxSize', 12) //In Mb
    .directive('certificateFileUploadComponent', function () {
      return {
        restrict: 'EA',
        controller: CertificateFileUploadController,
      };
    });

  /* @ngInject */
  function CertificateFileUploadController(rootScopeService, $scope, $http, $window, fileService, elms, _) {
    var uploadHandler = null;

    $scope.deleteCertificate = deleteCertificate;
    $scope.formatFileSize = formatFileSize;

    onInit();

    function destroyFile() {
      $scope.uploadedFile = null;
      $scope.queue = [];
      $scope.model.certificateFile = null;
      $scope.thumbnail = false;
      uploadHandler = null;
    }

    function onInit() {
      $scope.uploadedFile = $scope.model.certificateFile;
      $scope.isHTML5 = fileService.isHTML5;

      $scope.$on('fileuploadadd', function (e, data) {
        uploadHandler = data;
        $scope.uploadedFile = data.files[0];
        $scope.fileUploadError = null;
        rootScopeService.broadcast('event:fileUploadProgress', true);
      });

      $scope.$on('fileuploaddone', function (e, data) {
        var fileExtension;
        $scope.model.certificateFile = $scope.uploadedFile;
        $scope.model.certificateFile.tmpName = _.trim(data.result, '"');
        rootScopeService.broadcast('event:fileUploadProgress', false);

        fileExtension = $scope.model.certificateFile.tmpName.split('.').pop();
        if (_.includes($scope.certificateFileUpload.availableThumbnail, fileExtension)) {
          $scope.thumbnail = 'image';
        } else {
          $scope.thumbnail = fileExtension === 'pdf' ? 'pdf' : false;
        }
        uploadHandler = null;
      });

      $scope.$on('fileuploadprocessfail', function () {
        $scope.fileUploadError = $scope.uploadedFile.error;
        rootScopeService.broadcast('event:fileUploadProgress', false);
        destroyFile();
      });

      $scope.$on('fileuploadfail', function (e, data) {
        if (angular.isUndefined(data.result) && _.get(data, 'jqXHR.status', null) === 413) {
          data.result = 'File is too large';
        }
        $scope.fileUploadError = data.result;
        rootScopeService.broadcast('event:fileUploadProgress', false);
        destroyFile();
      });

      $scope.$on('fileuploadprogressall', function (e, data) {
        $scope.uploadedFile.uploadProgress = Math.min(100, parseInt((100.0 * data.loaded) / data.total, 10));
      });

      $scope.$on('$destroy', function () {
        if (uploadHandler) {
          uploadHandler.abort();
        }
      });
    }

    function deleteCertificate() {
      var fileName = $scope.model.certificateFile.tmpName;

      if (fileName) {
        destroyFile();

        if (
          !angular.isDefined($scope.certificateFileUpload.syncWithServerOnDelete) ||
          $scope.certificateFileUpload.syncWithServerOnDelete
        ) {
          $http({
            url: $scope.options.url,
            method: 'delete',
            params: { name: fileName },
          });
        }
      }
    }

    function formatFileSize(size) {
      return elms.formatBytes(size);
    }
  }
})();
