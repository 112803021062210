(function () {
  angular.module('kmi.lms.user.certificates').factory('userCertificatesService', userCertificatesService);

  /* @ngInject */
  function userCertificatesService(
    $http,
    $window,
    $uibModal,
    apiUrl,
    notificationService,
    $httpParamSerializer,
    courseRegistrationStatus,
    $q,
  ) {
    return {
      generateCertificate: generateCertificate,
      getRegistrationCertificates: getRegistrationCertificates,
      generateCertificatesByRegistrations: generateCertificatesByRegistrations,
      printCertificate: printCertificate,
      getCertificates: getCertificates,
      externalCertificateAvailable: externalCertificateAvailable,
      wrapCertificates: wrapCertificates,
      resetCertificate: resetCertificate,
    };

    function externalCertificateAvailable(registration, course) {
      course = course || registration.course;
      return (
        registration &&
        courseRegistrationStatus.strictCompletedSet.indexOf(registration.statusId) >= 0 &&
        course &&
        ![131, 132].includes(course.formatId)
      );
    }

    function getCertificates(userId, params) {
      return $http.get(['/a/user/', userId, '/certificates/'].join(''), { params: params }).then(function (response) {
        return response.data;
      });
    }

    function getRegistrationCertificates(registrationId) {
      return $http
        .get(['/a/course_registrations/', registrationId, '/certificates/'].join(''))
        .then(function (response) {
          return response.data;
        });
    }

    function generateCertificate(registrationId, certData) {
      if (certData.certTypeCategoryID === 2) {
        notificationService.error('Sorry, certificate is not available at the moment, please try again later.', 3e3);
      } else {
        var openURL = getCertificateUrl(registrationId, certData);

        if (openURL) {
          if (certData.creditTypeId) {
            openURL += [openURL.indexOf('?') === -1 ? '?' : '&', 'credit_type_id=' + certData.creditTypeId].join('');
          }

          $window.open(openURL);
        }
      }
    }

    function generateCertificatesByRegistrations(userId, params) {
      const endpoint = ['/a/user/', userId, '/certificates/content'].join('');
      $window.open([endpoint, $httpParamSerializer(params)].join('?'));
    }

    function resetCertificate(registrationId) {
      const endpoint = ['/a/course_registrations/', registrationId, '/certificates/reset_cache/'].join('');
      return $http.post(apiUrl(endpoint)).then(
        function () {
          notificationService.info('The certificate has been reset.', 5e3);
        },
        (reason) => {
          notificationService.hideInfo();
          if ([400, 403].includes(reason.status)) {
            notificationService.error(reason.data, 5e3);
          } else {
            notificationService.error('An error occurred during operation. Please try again later.', 5e3);
          }

          return $q.reject();
        },
      );
    }

    function printCertificate(registrationId, external = false) {
      $uibModal.open({
        component: 'certificatesListComponent',
        size: 'md',
        backdrop: 'static',
        resolve: {
          registrationId: function () {
            return registrationId;
          },
          external: function () {
            return external;
          },
        },
      });
    }

    function getCertificateUrl(registrationId, certData) {
      if (certData.targetEntityId === 3 || certData.certTypeCategoryID === 0) {
        //NonElms course or external certificate for registration
        return ['/a/certificates/external/', certData.certTypeId, '/content/'].join('');
      } else {
        //Course
        return [
          '/a/course_registrations/',
          registrationId,
          '/certificates-content/',
          certData.certTypeId ? certData.certTypeId + '/' : '',
        ].join('');
      }
    }

    function wrapCertificates(certificatesDataRaw) {
      var certToPrintSchema = [
          'certTypeId',
          'certTypeName',
          'certTypeCategoryID',
          'certTypeUrl',
          'random_security_code',
          'creditTypeId',
          'creditTypeName',
          'targetEntityId',
        ],
        certData = [];

      angular.forEach(certificatesDataRaw, function (certDataRaw) {
        var certDataItem = {};
        angular.forEach(certToPrintSchema, function (fieldName, fieldIdx) {
          certDataItem[fieldName] = certDataRaw[fieldIdx];
        });
        certData.push(certDataItem);
      });

      return certData;
    }
  }
})();
