(function () {
  angular.module('kmi.lms.user.registration').component('simpleUserRegistration', {
    template: require('ajs/modules/user/registration/components/simple-registration.html').default,
    controller: SimpleUserRegistrationController,
    controllerAs: 'vm',
    bindings: {
      step: '<',
      user: '<',
      emailAsLogin: '<?',
      registrationCode: '<?',
    },
  });

  /* @ngInject */
  function SimpleUserRegistrationController() {
    var vm = this;

    vm.$onInit = onInit;

    function onInit() {}
  }
})();
