(function () {
  angular.module('kmi.lms.user.common').directive('validUserRegistrationCode', function ($q, Group) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, elem, attr, ctrl) {
        var validationResults = {};

        ctrl.$asyncValidators.validUserRegistrationCode = function (modelValue) {
          var user = scope.$eval(attr.validUserRegistrationCode);

          if (ctrl.$isEmpty(modelValue)) {
            // consider empty model valid
            return $q.resolve();
          }

          var deferred = $q.defer();

          if (validationResults[modelValue]) {
            return validationResults[modelValue].promise;
          } else {
            validationResults[modelValue] = deferred;

            Group.query(
              {
                query: {
                  corporate_license_code: modelValue,
                },
              },
              function (response) {
                var group;

                if (response.items.length) {
                  group = response.items[0];
                  user.groups = [group];
                  deferred.resolve(group);
                } else {
                  deferred.reject();
                }
              },
            );
          }

          return deferred.promise;
        };
      },
    };
  });
})();
