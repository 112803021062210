(function () {
  angular.module('kmi.lms.user.notifications').component('userNotificationsBell', {
    template: require('ajs/modules/user/notifications/grouped/notifications-bell.html').default,
    controller: UserNotificationsGroupedController,
    controllerAs: 'vm',
    bindings: {
      showCount: '=',
      appearance: '<?',
    },
  });

  /* @ngInject*/
  function UserNotificationsGroupedController(
    rootScopeService,
    userNotificationsService,
    $cookies,
    $uibModal,
    $scope,
    kmiUserNotificationsAlertWasShown,
    _,
  ) {
    var vm = this;

    vm.notifications = {};
    vm.identifier = '';

    vm.show = show;

    vm.$onInit = activate;

    function activate() {
      vm.notifications = {
        count: 0,
      };

      $scope.$on('event:notifications:alert:showNotifications', vm.show);
      $scope.$on('event:notifications:updated', updateNotifications);

      userNotificationsService.check();
    }

    function updateNotifications(event, data) {
      // Grouping notifications per notification type
      var gn = _.groupBy(data.notifications, 'type');

      var n = { count: data.notifications.length };
      _.forEach(_.keys(gn), function (nt) {
        var ns = gn[nt];

        // As grouped value for notification type with multiple notifications we get first notification
        n[nt] = ns[0];
        n[nt].count = ns.length;
      });

      vm.identifier = data.identifier;
      vm.notifications = n;

      // Show notifications alert if it was not shown today for current user
      if (vm.notifications.count > 0 && $cookies.get(kmiUserNotificationsAlertWasShown) !== data.identifier) {
        showAlert();
      }
    }

    function showAlert() {
      rootScopeService.broadcast('event:notifications:alert:show', vm.notifications, vm.identifier);
    }

    function show() {
      $uibModal.open({
        component: 'userNotificationsGroupedModalComponent',
        resolve: {
          notifications: function () {
            return vm.notifications;
          },
        },
      });
    }
  }
})();
