(function () {
  /**
   * @ngdoc service
   * @name kmi.lms.user.common.service:userDictionaryService
   *
   * @description
   * Get dictionaries from User for autocomplete fields
   */

  angular.module('kmi.lms.user.common').factory('userDictionaryService', userDictionaryService);

  /* @ngInject */
  function userDictionaryService($http, $q, apiUrl) {
    var canceler;

    return {
      getOrganizations: getOrganizations,
      getCities: getCities,
    };

    function getValues(dictName, query) {
      if (canceler) {
        canceler.resolve();
      }
      canceler = $q.defer();

      return $http
        .get(apiUrl('/a/user/dictionary/'), {
          cache: false,
          timeout: canceler.promise,
          params: {
            dict_name: dictName,
            query: query,
          },
        })
        .then(function (response) {
          return response.data;
        });
    }

    function getOrganizations(query) {
      return getValues('organization', query);
    }

    function getCities(query) {
      return getValues('city', query);
    }
  }
})();
