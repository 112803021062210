(function () {
  angular.module('kmi.lms.user.components').component('saveProfileModalComponent', {
    template: require('ajs/modules/user/edit/components/modal/save-profile.html').default,
    controller: SaveProfileModalController,
    controllerAs: 'vm',
    bindings: {
      modalInstance: '=',
    },
  });

  function SaveProfileModalController() {
    var vm = this;

    vm.submit = function () {
      vm.modalInstance.close();
    };

    vm.cancel = function () {
      vm.modalInstance.dismiss('cancel');
    };
  }
})();
