(function () {
  angular.module('kmi.lms.search').directive('objectTags', objectTags);

  /* @ngInject */
  function objectTags($location, searchFilters, _) {
    return {
      restrict: 'EA',
      scope: {
        tags: '=',
      },
      template: require('ajs/modules/search/components/object-tags.html').default,
      link: function postLink(scope) {
        scope.filter = new searchFilters.TagFilter({ items: scope.tags });

        scope.$watch(
          function () {
            return $location.search();
          },
          function () {
            scope.filter.extractConditions();
          },
          true,
        );

        scope.tagSelected = tagSelected;

        function tagSelected(tag) {
          return _.includes(scope.filter.selectedOptions, tag);
        }
      },
    };
  }
})();
