(function () {
  angular.module('kmi.lms.user.common').component('accountCreatedConfirmation', {
    template: require('ajs/modules/user/common/account-created-confirmation.html').default,
    controller: AccountCreatedConfirmationController,
    controllerAs: 'vm',
  });

  function AccountCreatedConfirmationController(
    siteSettingService,
    globalConfig,
    currentUser,
    userEmailOptInService,
    userEmailOptInStatuses,
    backUrlService,
    _,
    notificationService,
    serverErrorHandlerService,
    $state,
  ) {
    const vm = this;

    vm.siteSettings = siteSettingService.getSiteSetting();
    vm.signInWording = globalConfig.signInWording;
    vm.user = currentUser.get();

    vm.$onInit = onInit;
    vm.resendEmail = resendEmail;

    function onInit() {
      if (_.get(vm.user, 'emailOptInOption.statusId') !== userEmailOptInStatuses.emailConfirmationPending) {
        backUrlService.transitionToLastState();
      }

      vm.supportLink =
        $state.href('main.contacts') || $state.href('main.support') || `mailto:${vm.siteSettings.supportEmail}`;
    }

    function resendEmail() {
      vm.processing = true;

      userEmailOptInService
        .resendAction()
        .then(
          function () {
            notificationService.info('Verification email sent successfully.', 2e3);
          },
          function (reason) {
            serverErrorHandlerService.handleForbiddenError(reason);
          },
        )
        .finally(() => {
          vm.processing = null;
        });
    }
  }
})();
