(function () {
  angular.module('kmi.lms.user.mail').component('emailConfirmedComponent', {
    template: require('ajs/modules/user/mail/opt_in/email-confirmed.html').default,
    controller: EmailConfirmedController,
    controllerAs: 'vm',
  });

  /* @ngInject */
  function EmailConfirmedController(siteSettingService, navigationService, currentUser, globalConfig, $state) {
    var vm = this;
    vm.$onInit = onInit;

    vm.siteSettings = {};
    vm.userSettings = {};
    vm.state = $state;
    vm.emailOptIn = globalConfig.settings.emailOptIn;
    vm.signInWording = globalConfig.signInWording;

    vm.goHome = navigationService.goHome;

    function onInit() {
      vm.siteSettings = siteSettingService.getSiteSetting();
      vm.userSettings = currentUser.get();
    }
  }
})();
