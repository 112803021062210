(function () {
  angular.module('kmi.lms.user.components').component('reconfirmEmailRequiredDialog', {
    template: require('ajs/modules/user/edit/components/modal/reconfirm-email-required-dialog.html').default,
    controller: ReconfirmEmailRequiredDialogController,
    controllerAs: 'vm',
    bindings: {
      modalInstance: '<',
    },
  });

  /* @ngInject */
  function ReconfirmEmailRequiredDialogController(siteSettingService, authorizationService) {
    const vm = this;

    vm.siteSettings = siteSettingService.getSiteSetting();
    vm.cancel = cancel;
    vm.$onInit = onInit;

    function onInit() {
      authorizationService.removeCookie();
    }

    function cancel() {
      vm.modalInstance.dismiss();
    }
  }
})();
