(function () {
  angular.module('kmi.lms.search.common').service('searchFilterCollectionTypes', searchFilterCollectionTypes);

  /* @ngInject */
  function searchFilterCollectionTypes($q, searchService, _) {
    var service = {
      getTrainingCategoriesFilterConfig: getTrainingCategoriesFilterConfig,
    };

    return service;

    function getTrainingCategoriesFilterConfig(configOptions) {
      return {
        getOptions: getOptions,
      };

      function getOptions(options) {
        return angular.extend(
          {
            term: 'training_category',
            label: 'Training Categories',
            mode: 'multiChoice',
            items: [],
            dependency: {
              condition: 'course',
            },
            load: function () {
              var searchParams = {
                  type: 'training_category',
                  active: true,
                },
                promises = [searchService.get({ mode: 'public' }).search(searchParams)];

              if (configOptions.mode === 'admin') {
                promises.push(searchService.get({ mode: 'admin' }).search(searchParams));
              }

              return $q.all(promises).then(
                function (results) {
                  var items = [];
                  _.each(results, function (response) {
                    items = _.concat(
                      items,
                      _.filter(
                        _.map(response.data.items, function (item) {
                          return {
                            id: item.id,
                            text: item.title,
                            value: item.id + '',
                          };
                        }),
                        function (item) {
                          return !_.find(items, { id: item.id });
                        },
                      ),
                    );
                  });

                  this.items = _.orderBy(items, 'text');
                  this._initSelectedItems();
                }.bind(this),
              );
            },
          },
          options,
        );
      }
    }
  }
})();
