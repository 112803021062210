(function () {
  angular.module('kmi.lms.user.common').component('userOrganizationInput', {
    template: require('ajs/modules/user/components/user-organization-input.html').default,
    controller: UserPhoneInputController,
    controllerAs: 'vm',
    bindings: {
      user: '=',
      formSubmitted: '=',
      requiredField: '<?',
    },
  });

  function UserPhoneInputController() {
    const vm = this;

    vm.$onInit = onInit;
    function onInit() {
      vm.requiredField = angular.isDefined(vm.requiredField) ? vm.requiredField : true;
    }
  }
})();
