import { Subject } from 'rxjs';

(function () {
  angular.module('kmi.lms.user.components').directive('editAccount', editUser);

  function editUser() {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        user: '=',
      },
      template: require('ajs/modules/user/edit/components/edit-control.html').default,
      controller: EditUserComponentController,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  /* @ngInject */
  function EditUserComponentController(
    $scope,
    $http,
    $q,
    $uiRouterGlobals,
    $interval,
    $timeout,
    notificationService,
    urlVersionService,
    formService,
    serverErrorHandlerService,
    customUserAttributesService,
    _,
    attributesService,
    globalConfig,
  ) {
    var vm = this;
    var defer = null;
    vm.currentTab = $uiRouterGlobals.params.activeView || 'mainDescriptionForm';
    vm.formOptions = { submitted: false };
    vm.requiredFieldsStates = {};
    vm.completionFormsStates = {};
    vm.isFormIncomplete = {};
    vm.networkSettings = null;
    vm.settings = globalConfig.settings;

    vm.submitForm = submitForm;
    vm.$onInit = activate;
    vm.groupChangedSubject = new Subject();
    vm.hideGroupSelection = true;

    function activate() {
      $scope.$on('event:modelCompleteness:changed', updateProfileCompletion);

      $scope.$watch(
        'vm.user.groups',
        function (newValue, oldValue) {
          if (newValue && newValue.length) {
            if (!angular.equals(_.sortBy(newValue), _.sortBy(oldValue))) {
              if (defer && _.some($http.pendingRequests, { timeout: defer.promise })) {
                defer.resolve();
              }
              loadCustomUserAttributes();
            }
          } else {
            vm.categories = [];
          }
        },
        true,
      );

      loadCustomUserAttributes().then(function () {
        $scope.$watch('vm.userForm.$valid', changingFormHandler);
      });

      $scope.$on('$destroy', function () {
        vm.groupChangedSubject.complete();
      });

      vm.groupChangedSubject.subscribe((groups) => {
        vm.user.groups = groups;
      });
    }

    function loadCustomUserAttributes() {
      defer = $q.defer();
      vm.componentLoaded = false;

      var promise = customUserAttributesService.getAttributeCategories(
        {
          withAttributes: true,
          groups: angular.toJson(_.map(vm.user.groups, 'id')),
        },
        vm.user.id,
        defer.promise,
      );

      promise
        .then(function (res) {
          if (res.length && !_.get(globalConfig, 'settings.user.customAttributesDisabled')) {
            vm.categories = res;
            vm.componentLoaded = true;
          }
        })
        .catch(function (reason) {
          serverErrorHandlerService.handleForbiddenError(reason);
        });

      return promise;
    }

    function changingFormHandler(newValue, oldValue) {
      if (newValue !== oldValue) {
        recalculateCompletion();
      }
    }

    function recalculateCompletion() {
      formService.fulfillCompleteness(vm.userForm).then(function (result) {
        vm.isFormIncomplete = result.isFormIncomplete;
        vm.requiredFieldsStates = result.requiredFieldsStates;
        vm.completionFormsStates = result.completenessFormsStates;
      });

      $timeout(function () {
        if (vm.currentTab === 'mainAttributesForm') {
          var changeCurrentTab = true;
          _.forEach(vm.categories, function (category) {
            var forName = attributesService.getFormName(category);
            var form = vm.isFormIncomplete[forName];
            if (form && changeCurrentTab) {
              changeCurrentTab = false;
              vm.currentTab = forName;
            }
          });
        }
      }, 0);
    }

    function updateProfileCompletion(event, state) {
      _.assign(vm.requiredFieldsStates, state.input);
      _.merge(vm.completionFormsStates, state.form);
      fulfillProfileCompletion();
    }

    function fulfillProfileCompletion() {
      let completeness = formService.calculateCompleteness(vm.completionFormsStates, vm.requiredFieldsStates);
      vm.isFormIncomplete = completeness.isFormIncomplete;
    }

    function submitForm() {
      vm.formOptions.submitted = true;
      $scope.$emit('event:validation.pending', true);

      let validationPending = $interval(function () {
        if (!vm.userForm.$pending) {
          if (!$http.pendingRequests.length) {
            $interval.cancel(validationPending);
            $scope.$broadcast('event:customAttributes.saving', vm.user);

            if (vm.userForm.$valid) {
              let userDraft = angular.copy(vm.user);

              userDraft
                .save()
                .then(function () {
                  if (vm.networkSettings) {
                    vm.networkSettings.userId = vm.user.id;
                    return vm.networkSettings.$save(function (network) {
                      angular.extend(vm.user.userNetwork.profile, network);
                    });
                  }
                })
                .then(
                  function () {
                    angular.extend(vm.user, userDraft);
                    urlVersionService.update();
                    $scope.$emit('event:user.saved', vm.user);
                  },
                  function (response) {
                    var message = 'An error occurred saving user data. Please try again later.';
                    if (response.status === 403 && response.data && response.data.code) {
                      message = response.data.message;
                    }

                    notificationService.error(message, 5e3);
                  },
                )
                .finally(function () {
                  $scope.$emit('event:validation.pending', false);
                });
            } else {
              notificationService.error('Please fill in all required fields', 2e3);
              $scope.$emit('event:validation.pending', false);
            }
          }
        }
      }, 100);
    }
  }
})();
