(function () {
  var questionTypeExtension = Object.freeze({
    trueFalse: {
      id: 1,
      typeId: 1,
    },
    likertScale: {
      id: 2,
      typeId: 1,
    },
    yesNo: {
      id: 5,
      typeId: 1,
    },
  });

  let quizQuestionType = Object.freeze({
      multiChoice: 1,
      checkAllThatApply: 2,
      shortAnswer: 3,
      matching: 4,
      fillin: 5,
    }),
    quizResultStatus = Object.freeze({
      inProgress: 1,
      completed: 2,
      passed: 3,
      failed: 4,
      pending: 5,
    }),
    quizTypes = Object.freeze({
      preAssessment: 1,
      assessment: 2,
      evaluation: 3,
      exam: 4,
      survey: 5,
      preEvaluation: 7,
      followupEvaluation: 8,
    }),
    quizTypeCategories = Object.freeze({
      assessment: 2,
      evaluation: 3,
    }),
    categoryQuizTypes = Object.freeze({
      assessment: [1, 2],
      evaluation: [3, 7, 8],
    }),
    systemQuizTypes = Object.freeze([2, 3]),
    quizIntendedFor = Object.freeze({
      learners: 1,
      administrators: 2,
    }),
    availableQuestionTypes = Object.freeze({
      //preAssessment:
      1: [
        quizQuestionType.multiChoice,
        quizQuestionType.checkAllThatApply,
        quizQuestionType.shortAnswer,
        quizQuestionType.matching,
        quizQuestionType.fillin,
      ], //assessment:
      2: [
        quizQuestionType.multiChoice,
        quizQuestionType.checkAllThatApply,
        quizQuestionType.shortAnswer,
        quizQuestionType.matching,
        quizQuestionType.fillin,
      ], // evaluation:
      3: [quizQuestionType.multiChoice, quizQuestionType.checkAllThatApply, quizQuestionType.shortAnswer], // exam:
      4: [
        quizQuestionType.multiChoice,
        quizQuestionType.checkAllThatApply,
        quizQuestionType.shortAnswer,
        quizQuestionType.matching,
        quizQuestionType.fillin,
      ], // survey:
      5: [
        quizQuestionType.multiChoice,
        quizQuestionType.checkAllThatApply,
        quizQuestionType.shortAnswer,
        quizQuestionType.fillin,
      ],
    }),
    availableQuestionTypeExtensions = Object.freeze({
      //preAssessment:
      1: [questionTypeExtension.trueFalse, questionTypeExtension.yesNo], //assessment:
      2: [questionTypeExtension.trueFalse, questionTypeExtension.yesNo], // evaluation:
      3: [questionTypeExtension.trueFalse, questionTypeExtension.yesNo, questionTypeExtension.likertScale], // exam:
      4: [questionTypeExtension.trueFalse, questionTypeExtension.yesNo], // survey:
      5: [questionTypeExtension.trueFalse, questionTypeExtension.yesNo],
    });

  var typeExtensionTemplates = [
    {
      extensionId: questionTypeExtension.trueFalse.id,
      answerVariants: [
        {
          text: 'True',
          correct: true,
        },
        {
          text: 'False',
          correct: false,
        },
      ],
    },
    {
      extensionId: questionTypeExtension.yesNo.id,
      answerVariants: [
        {
          text: 'Yes',
          correct: true,
        },
        {
          text: 'No',
          correct: false,
        },
      ],
    },
    {
      extensionId: questionTypeExtension.likertScale.id,
      answerVariants: [
        {
          text: 'Strongly Agree',
          correct: true,
        },
        {
          text: 'Agree',
          correct: false,
        },
        {
          text: 'Neutral',
          correct: false,
        },
        {
          text: 'Disagree',
          correct: false,
        },
        {
          text: 'Strongly Disagree',
          correct: false,
        },
      ],
    },
  ];

  angular
    .module('kmi.lms.quiz')
    .constant('quizEnums', {
      resultStatus: quizResultStatus,
      quizTypes: quizTypes,
      contentQuizTypes: [quizTypes.exam, quizTypes.survey],
      quizTypeCategories: quizTypeCategories,
      categoryQuizTypes: categoryQuizTypes,
      quizIntendedFor: quizIntendedFor,
      systemQuizTypes: systemQuizTypes,
    })
    .value('quizQuestionEnums', {
      questionType: quizQuestionType,
      availableQuestionTypes: availableQuestionTypes,
      questionTypeExtension: questionTypeExtension,
      typeExtensionTemplates: typeExtensionTemplates,
      availableQuestionTypeExtensions: availableQuestionTypeExtensions,
    })
    .value('quizOptionsEnum', {
      timeTrackingAttemptsEnabled: false,
      showTitle: false,
    });
})();
