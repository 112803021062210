(function () {
  angular
    .module('kmi.lms.user.common', ['kmi.lms.core', 'kmi.lms.course.registrations'])
    .config(moduleConfig)
    .run(moduleRun)
    .constant(
      'regexEmailPattern', // RFC 2822 standard email validation
      /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
    );

  function moduleConfig($stateProvider) {
    $stateProvider.state('prompt.accountCreated', {
      url: '/account-created-confirmation',
      component: 'accountCreatedConfirmation',
      data: {
        label: 'Account created',
        availableFor: ['regularUser', 'admin'],
      },
    });
  }

  function moduleRun(globalConfig) {
    globalConfig.settings.authorizedStates.push({
      name: 'prompt.accountCreated',
      params: {},
    });
    globalConfig.settings.deepLinkingExcludedStates.push('prompt.accountCreated');
  }
})();
