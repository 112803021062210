(function () {
  angular.module('kmi.lms.user.mail').component('userEmailOptInModalComponent', {
    template: require('ajs/modules/user/mail/opt_in/modal/opt-in.html').default,
    controller: UserEmailOptInModalController,
    controllerAs: 'vm',
    bindings: {
      resolve: '=',
      modalInstance: '=',
    },
  });

  /* @ngInject */
  function UserEmailOptInModalController(userEmailOptInService, siteSettingService) {
    var vm = this;
    vm.$onInit = onInit;

    vm.view = 'initial';
    vm.siteSettings = siteSettingService.getSiteSetting();
    vm.processing = false;

    vm.confirmOptIn = confirmOptIn;
    vm.confirmOptOut = confirmOptOut;

    function onInit() {
      vm.userEmail = vm.resolve.userEmail;
      vm.cancel = vm.modalInstance.dismiss;
    }

    function confirmOptIn() {
      vm.processing = true;

      userEmailOptInService.optInAction().then(
        function () {
          vm.processing = false;
          vm.modalInstance.close();
        },
        function () {
          vm.processing = false;
        },
      );
    }

    function confirmOptOut() {
      vm.processing = true;

      userEmailOptInService.optOutAction().then(
        function () {
          vm.processing = false;
          vm.modalInstance.close();
        },
        function () {
          vm.processing = false;
        },
      );
    }
  }
})();
