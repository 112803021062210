(function () {
  angular.module('kmi.lms.user.achievements').factory('UserAchievement', userAchievementService);

  /* @ngInject */
  function userAchievementService($resource) {
    return $resource(
      '/a/user/:userId/achievements/',
      { userId: '@id' },
      {
        update: { method: 'PUT' },
        available: {
          method: 'GET',
          url: '/a/user/:userId/achievements/available/',
          params: { userId: '@id' },
          isArray: true,
        },
        group_available: {
          method: 'GET',
          url: '/a/user/:userId/achievements/:achievementId/group/available/',
          params: { userId: '@id', achievementId: '@achievementId' },
        },
        stat: {
          method: 'GET',
          url: '/a/user/:userId/achievements/',
          params: { userId: '@id', stat_only: true },
          isArray: false,
        },
        stat_available: {
          method: 'GET',
          url: '/a/user/:userId/achievements/available/',
          params: { userId: '@id', stat_only: true },
          isArray: false,
        },
      },
      { stripTrailingSlashes: false },
    );
  }
})();
