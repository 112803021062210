(function () {
  angular
    .module('kmi.lms.user.notifications', [
      'ngCookies',
      'globalDependencies',
      'kmi.lms.core',
      'kmi.lms.course.registrations',
      'kmi.lms.user.mail',
    ])
    .constant('kmiUserNotificationsAlertWasShown', 'kmiunaws')
    .constant('kmiUserNotificationsViewed', 'kmiunv');
})();
