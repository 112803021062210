(function () {
  angular.module('kmi.lms.user.notifications').component('userNotificationGroupedAlert', {
    controller: UserNotificationsGroupedAlertCtrl,
    controllerAs: 'vm',
    template: require('ajs/modules/user/notifications/grouped/notifications-alert.html').default,
  });

  /* @ngInject */
  function UserNotificationsGroupedAlertCtrl(
    $scope,
    rootScopeService,
    $cookies,
    $timeout,
    kmiUserNotificationsAlertWasShown,
    _,
  ) {
    var vm = this;

    vm.profileIncomplete = false;
    vm.showAlert = false;

    vm.hideAlert = hideAlert;
    vm.showNotifications = showNotifications;

    activate();

    function activate() {
      $scope.$on('event:notifications:alert:show', function (event, notifications, identifier) {
        vm.notificationsCount = notifications.count;
        vm.identifier = identifier;
        vm.profileIncomplete = _.has(notifications, 'profileIncomplete');
        vm.showAlert = true;
        setCookie();

        $timeout(function () {
          vm.hideAlert();
        }, 5e3);
      });
    }

    function hideAlert() {
      vm.showAlert = false;
    }

    function showNotifications(event) {
      if (event) {
        event.preventDefault();
      }

      vm.hideAlert();
      rootScopeService.broadcast('event:notifications:alert:showNotifications');
    }

    function setCookie() {
      var today = new Date();
      var expires = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);

      // Add record to cookie about alert was shown today for current user
      $cookies.put(kmiUserNotificationsAlertWasShown, vm.identifier, { path: '/', expires: expires });
    }
  }
})();
