(function () {
  angular.module('kmi.lms.user.common').component('usernameInput', {
    template: require('ajs/modules/user/components/username-input.html').default,
    controller: UsernameInputController,
    controllerAs: 'vm',
    bindings: {
      user: '=',
      formSubmitted: '=',
    },
  });

  function UsernameInputController(globalConfig, _) {
    const vm = this;

    vm.$onInit = onInit;
    function onInit() {
      vm.hiddenControl = _.get(globalConfig, 'settings.user.emailAsLogin');
    }
  }
})();
