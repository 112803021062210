(function () {
  angular.module('kmi.lms.search.common').service('searchFilterBase', searchFilterBase);

  /* @ngInject */
  function searchFilterBase($location, _) {
    var service = {
      get: get,
    };
    return service;

    ////////////

    function get() {
      return {
        appearance: 'panel',
        displayLimit: 10,
        init: init,
        exec: exec,
        batchExec: batchExec,
        clearSelection: clearSelection,
        extractConditions: extractConditions,
        _initSelectedItems: _initSelectedItems,
        _getExistingConditions: _getExistingConditions,
        _setExistingConditions: _setExistingConditions,
        _setVisibility: _setVisibility,
        load: load,
      };

      /**
       * @description
       * Stub to avoid error
       */
      function init() {}

      /**
       * @description
       * Toggle provided search criteria. Adds it to the search query if it hasn't already exists or
       * removes in the another case.
       * If search filter allow multiple options they'll be separated by the comma
       * @param value
       */
      function exec(value) {
        var existingConditions = this._getExistingConditions();

        //Cast to string and trim
        value = (value + '').trim();

        if (
          !_.find(existingConditions, function (i) {
            return i.trim() === value;
          })
        ) {
          if (this.mode === 'multiChoice' || this.mode === 'searchableMultiChoice' || this.mode === 'promptingFilter') {
            existingConditions.push(value);
          } else {
            existingConditions = [value];
          }
        } else {
          existingConditions = _.without(existingConditions, value);
        }

        this._setExistingConditions(existingConditions);
      }

      /**
       * @description
       * Toggle provided search criteria. Adds them to the search query if some hasn't already exists or
       * removes in the another case.
       * Work only with search filter that allow multiple options
       * @param items
       */
      function batchExec(items) {
        if (this.mode === 'multiChoice' || this.mode === 'searchableMultiChoice' || this.mode === 'promptingFilter') {
          var existingConditions = this._getExistingConditions();

          _.forEach(items, function (item) {
            //Cast to string and trim
            var value = (item.value + '').trim();
            if (
              !_.find(existingConditions, function (i) {
                return i.trim() === value;
              })
            ) {
              existingConditions.push(value);
            } else {
              existingConditions = _.without(existingConditions, value);
            }
          });

          this._setExistingConditions(existingConditions);
        }
      }

      /**
       * @description
       * Clears selected option for the filter. Removes search criteria from the query string
       */
      function clearSelection() {
        var search = $location.search();
        if (search[this.term]) {
          delete search[this.term];
        }
        if (this.subfilter !== undefined) {
          this.subfilter.clearSelection();
        }
        $location.search(search);
      }

      function _setVisibility() {
        if (this.dependency) {
          var filter,
            that = this,
            oldVisible = this.visible;

          this.visible = _.every(
            _.isArray(this.dependency) ? this.dependency : [this.dependency],
            function (dependency) {
              switch (typeof dependency) {
                case 'boolean':
                  return dependency;

                case 'function':
                  return dependency.call(that);

                case 'object':
                  if (dependency.filter) {
                    if (typeof dependency.filter === 'function') {
                      filter = new dependency.filter(); // eslint-disable-line new-cap
                    } else {
                      filter = dependency.filter;
                    }
                    filter.extractConditions();

                    if (dependency.condition) {
                      return (
                        _.intersection(
                          filter.selectedOptions,
                          _.isArray(dependency.condition) ? dependency.condition : [dependency.condition],
                        ).length > 0
                      );
                    } else {
                      return !!(filter.selectedOptions && filter.selectedOptions.length);
                    }
                  }
                  return false;

                default:
                  return false;
              }
            },
          );

          if (oldVisible && !this.visible) {
            this.clearSelection();
          }

          this.dispatchEvent('filtersChanged');
        } else {
          this.visible = true;
        }
      }

      /**
       * @description
       * Parse query string and find search options related to the filter condition.
       * If filter have dependant filters this method will be called for them too.
       */
      function extractConditions() {
        this.selectedOptions = this._getExistingConditions();

        this._initSelectedItems();
      }

      /**
       * @description
       * Iterates through the filter options and mark option as "selected"
       * if corresponding option exists in the query string
       */
      function _initSelectedItems() {
        var that = this;
        this.items = _.map(this.items, function (item) {
          return angular.extend(item, { selected: _.includes(that.selectedOptions || [], item.value) });
        });

        this.selectedItems = _.filter(this.items, 'selected');

        if (this.unassignedOption) {
          this.unassignedOption.selected = _.includes(that.selectedOptions || [], this.unassignedOption.value);

          if (this.unassignedOption.selected) {
            this.selectedItems.push(this.unassignedOption);
          }
        }

        this._setVisibility();
      }

      /**
       * @description
       * Get array of selected options for current filter
       */
      function _getExistingConditions() {
        var search = $location.search(),
          existingConditions = [];

        if (search[this.term]) {
          existingConditions = search[this.term].split(',');
        }

        return existingConditions;
      }

      /**
       * @description
       * Set selected options for current filter from provided array
       * @param existingConditions
       */
      function _setExistingConditions(existingConditions) {
        var search = $location.search();

        existingConditions = _.uniq(existingConditions).join(',');
        if (existingConditions) {
          search[this.term] = existingConditions;
        } else {
          //remove empty condition
          delete search[this.term];
        }

        //update query string
        $location.search(search);
      }

      function load() {
        return Promise.resolve();
      }
    }
  }
})();
