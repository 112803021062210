(function () {
  angular.module('kmi.lms.user.learning').component('userLearningDashboard', {
    template: require('ajs/modules/user/learning/dashboard/dashboard.html').default,
    controller: UserLearningDashboardController,
    controllerAs: 'vm',
    bindings: {
      user: '<',
    },
  });

  /* @ngInject */
  function UserLearningDashboardController(userLearningDashboardService, globalConfig, _) {
    var vm = this;

    vm.dashboardCards = globalConfig.settings.userDashboard.cards;
    vm.$onInit = onInit;

    function maxCardsLength() {
      return Math.max(...vm.dashboardCards.map((section) => section.cards.length));
    }

    vm.maxCardCount = maxCardsLength() || 4;

    function onInit() {
      var allCards = _.flatten(
        _.map(vm.dashboardCards, function (section) {
          return _.map(section.cards, 'name');
        }),
      );

      userLearningDashboardService.getDashboard(vm.user.id, { cards: allCards }).then(function (dashboardData) {
        vm.dashboardData = dashboardData;
      });
    }
  }
})();
