(function () {
  angular.module('kmi.lms.user.certificates').component('certificatesListComponent', {
    template: require('ajs/modules/user/certificates/print/certificates-list-modal.html').default,
    controller: CertificatesListController,
    controllerAs: 'vm',
    bindings: {
      resolve: '=',
      modalInstance: '=',
    },
  });

  /* @ngInject */
  function CertificatesListController(
    CourseRegistration,
    LearningObjectRegistration,
    userCertificatesService,
    _,
    $q,
    currentUser,
    ExternalCourse,
    courseEnums,
    collectionsService,
  ) {
    let vm = this;
    vm.$onInit = onInit;
    vm.cancel = cancel;
    vm.printCertificate = printCertificate;
    vm.printAllCertificates = printAllCertificates;

    function onInit() {
      vm.registrationId = vm.resolve.registrationId;
      vm.certificatesCount = 0;
      vm.certifiedComponents = [];
      vm.certifiedRegistrations = [];
      vm.loading = true;

      getRegistrationData()
        .then(function (registration) {
          vm.registration = registration;
          processCertificates(registration);
          return registration;
        })
        .then((registration) => {
          processNestedCertificates(registration);
        });
    }

    function getRegistrationData() {
      return $q.when(true).then(() => {
        if (vm.resolve.external) {
          return ExternalCourse.get({ userId: currentUser.get().id, courseId: vm.registrationId }).$promise.then(
            function (registration) {
              return {
                certificates_data: registration.certificatesData,
                courseName: registration.title,
                format_name: registration.format ? registration.format.name : '',
              };
            },
          );
        } else {
          return CourseRegistration.get({ registrationId: vm.registrationId }).$promise.then(function (registration) {
            let reg = new LearningObjectRegistration({ id: registration.courseId });
            reg.init(registration);
            return reg;
          });
        }
      });
    }

    function processCertificates(registration) {
      const wrapCertificates = userCertificatesService.wrapCertificates(
        _.get(registration, 'certificates_data.certificates_list', []),
      );

      vm.certifiedCourse = {
        course: registration.course,
        registrationId: registration.id,
        certificate: null,
        certifiedCredits: [],
      };

      wrapCertificates.forEach((certificate) => {
        if (!_.get(certificate, 'creditTypeId')) {
          vm.certifiedCourse.certificate = certificate;
        } else {
          vm.certifiedCourse.certifiedCredits.push(certificate);
        }
      });

      if (wrapCertificates.length) {
        vm.certificatesCount += wrapCertificates.length;
        vm.certifiedRegistrations.push(registration.id);
      }
    }

    function processNestedCertificates(registration) {
      if (!vm.resolve.external && registration.type_id === courseEnums.formatType.collection) {
        return collectionsService
          .getComponentsEarnedCertificates(registration.courseId)
          .then(function (components) {
            components.forEach((component) => {
              const componentObj = {
                course: component.course,
                registrationId: component.registrationId,
                certificate: null,
                certifiedCredits: [],
              };

              const wrappedCertificates = userCertificatesService.wrapCertificates(component.certificates);
              wrappedCertificates.forEach((certificate) => {
                if (!_.get(certificate, 'creditTypeId')) {
                  componentObj.certificate = certificate;
                } else {
                  componentObj.certifiedCredits.push(certificate);
                }
              });

              if (wrappedCertificates.length) {
                vm.certifiedRegistrations.push(component.registrationId);
                vm.certificatesCount += wrappedCertificates.length;
                vm.certifiedComponents.push(componentObj);
              }
            });
          })
          .finally(() => {
            vm.loading = null;
          });
      } else {
        vm.loading = null;
      }
    }

    function printCertificate(registrationId, certificate) {
      userCertificatesService.generateCertificate(registrationId, certificate);
    }

    function printAllCertificates() {
      // Print all
      userCertificatesService.generateCertificatesByRegistrations(vm.registration.userId, {
        // @ts-ignore TS2802
        registrationIds: [...new Set(vm.certifiedRegistrations)],
      });
    }

    function cancel() {
      vm.modalInstance.dismiss('cancel');
    }
  }
})();
