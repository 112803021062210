(function () {
  angular.module('kmi.lms.user.learning').component('userLearningDashboardCard', {
    template: require('ajs/modules/user/learning/dashboard/dashboard-card.html').default,
    controller: UserLearningDashboardCardController,
    controllerAs: 'vm',
    bindings: {
      user: '<',
    },
  });

  /* @ngInject */
  function UserLearningDashboardCardController(
    $scope,
    userLearningDashboardService,
    globalConfig,
    $state,
    $location,
    userLearningDashboardCardSettings,
    courseRegistrationStatus,
    _,
    backUrlService,
  ) {
    var vm = this;

    vm.backUrl = backUrlService;
    vm.dashboardCards = globalConfig.settings.userDashboard.cards;
    vm.appSettings = globalConfig.settings;
    vm.itemsPerPage = userLearningDashboardCardSettings.itemsPerPage;
    vm.sortingOnServer = false;
    vm.showWithdrawn = false;
    vm.$onInit = onInit;
    vm.changeSelected = changeSelected;
    vm.changeCustomFilter = changeCustomFilter;
    vm.sortOrder = sortOrder;
    vm.pageChanged = pageChanged;
    vm.downloadTranscript = downloadTranscript;

    vm.isColumnAvailable = userLearningDashboardService.isColumnAvailableForView;

    function onInit() {
      var search = $location.search();
      vm.currentSection = _.find(vm.dashboardCards, function (section) {
        return _.includes(_.map(section.cards, 'name'), search.selected);
      });
      search.page = search.page || 1;
      vm.currentPage = search.page;
      vm.showWithdrawn = !!search.showWithdrawn;

      vm.selectedCard = _.find(vm.currentSection.cards, function (card) {
        return card.name === search.selected;
      });

      if (search.dir) {
        vm.reverse = search.dir === 'desc';
      } else {
        vm.reverse =
          vm.selectedCard.defaultSort && vm.selectedCard.defaultSort.dir
            ? vm.selectedCard.defaultSort.dir === 'desc'
            : false;
      }

      vm.predicate = search.sort || (vm.selectedCard.defaultSort ? vm.selectedCard.defaultSort.field : 'course.name');

      //Perform search by conditions passed to the query string
      $scope.$watch(
        function () {
          return $location.search();
        },
        onSearchChanged,
        true,
      );

      $scope.$on('event:course.registration.updated', loadRegistrations);

      loadRegistrations();
    }

    function changeCustomFilter() {
      // change filter
      var search = $location.search();

      if (vm.selectedCard.name === 'transcript') {
        search.showWithdrawn = vm.showWithdrawn;
        vm.currentPage = 1;
        search.page = 1;
      }

      $location.search(search);
    }

    function onSearchChanged(newValue, oldValue) {
      if (!_.isEqual(newValue, oldValue) && !vm.skipLoad) {
        if (vm.selectedCard.name !== newValue.selected) {
          // from main menu exists call that can change selected card.
          // In this case we need initiate call card change
          var card = _.find(vm.currentSection.cards, function (card) {
            return card.name === newValue.selected;
          });

          changeSelected(card);
        } else {
          loadRegistrations();
        }
      } else {
        vm.skipLoad = false;
      }
    }

    function loadRegistrations() {
      var parameters = angular.copy($location.search());
      angular.extend(parameters, {
        cards: vm.dashboardCards.flatMap((item) => (item.cards || []).map((card) => card.name)),
        count: vm.itemsPerPage,
      });

      if (vm.selectedCard.name === 'transcript' && parameters.dir === undefined && parameters.sort === undefined) {
        parameters.sort = 'lastCompletionDate';
        parameters.dir = 'desc';
      } else if (
        vm.selectedCard.name === 'certificates' &&
        parameters.dir === undefined &&
        parameters.sort === undefined
      ) {
        parameters.sort = 'completion_date';
        parameters.dir = 'desc';
      }

      vm.dataLoading = userLearningDashboardService
        .getDashboard(vm.user.id, parameters)
        .then(function (dashboardData) {
          vm.dashboardData = dashboardData;
          vm.sortingOnServer = vm.dashboardData[vm.selectedCard.name].count > vm.itemsPerPage;

          if (!vm.sortingOnServer) {
            angular.forEach(vm.dashboardData[vm.selectedCard.name].items, function (value) {
              angular.extend(value, {
                statusNameSorting: getStatus(value),
              });
            });
          }

          angular.forEach(vm.dashboardData[vm.selectedCard.name].items, function (value) {
            angular.extend(value, {
              name: value.title || value.course.name,
            });
          });
        })
        .finally(function () {
          vm.dataLoading = null;
        });
    }

    function getStatus(node) {
      var status;

      if (
        _.includes(
          ['incompleteExpired', 'incomplete', 'mandatory', 'collections', 'saved', 'evaluationPending'],
          vm.selectedCard.name,
        )
      ) {
        status = node.lastRegistration ? node.lastRegistration.statusId : null;
      } else {
        status = node.lastCompletedRegistration ? node.lastCompletedRegistration.statusId : node.statusId;
      }
      status = status || courseRegistrationStatus.notStarted;

      return courseRegistrationStatus.names[status];
    }

    function changeSelected(card) {
      if (card.name !== vm.selectedCard.name) {
        vm.selectedCard = card;

        var searchParams = $location.search();

        vm.currentPage = 1;
        vm.reverse =
          vm.selectedCard.defaultSort && vm.selectedCard.defaultSort.dir
            ? vm.selectedCard.defaultSort.dir === 'desc'
            : false;
        vm.predicate = vm.selectedCard.defaultSort ? vm.selectedCard.defaultSort.field : 'course.name';
        searchParams.selected = card.name;
        searchParams.dir = 'asc';
        searchParams.sort = vm.predicate;
        searchParams.page = 1;

        if (card.name !== 'transcript') {
          vm.showWithdrawn = false;
          delete searchParams.showWithdrawn;
        }

        $location.search(searchParams);
        vm.sectionOpen = false;
      }
    }

    function sortOrder(predicate, reverse) {
      vm.reverse = angular.isDefined(reverse) ? reverse : _.isEqual(vm.predicate, predicate) ? !vm.reverse : false;
      vm.predicate = predicate;
      vm.skipLoad = !vm.sortingOnServer;

      var searchQuery = $location.search();

      angular.extend(searchQuery, {
        sort: vm.predicate,
        dir: vm.reverse ? 'desc' : 'asc',
      });
      $location.search(searchQuery);
    }

    function pageChanged() {
      var searchQuery = $location.search();
      searchQuery.page = vm.currentPage;
      $location.search(searchQuery);
    }

    function downloadTranscript() {
      var options = angular.copy($location.search());
      angular.extend(options, { cards: [vm.selectedCard.name] });
      options = _.omit(options, 'page');
      userLearningDashboardService.exportCourses('transcript', vm.user.id, options);
    }
  }
})();
