export const userRegistrationSteps = {
  userInfoRegistration: {
    id: 'userInfoRegistration',
    title: 'Create an account',
  },
  simpleUserRegistration: {
    id: 'simpleUserRegistration',
    title: 'Create an account',
  },
  selfRegistrationGroupsSelection: {
    id: 'selfRegistrationGroupsSelection',
    title: 'Group selection',
  },
  userAttributesRegistration: {
    id: 'userAttributesRegistration',
    title: 'Fill required attributes',
  },
};
