(function () {
  angular.module('kmi.lms.search', ['kmi.lms.core', 'kmi.lms.components', 'kmi.lms.search.common']);
  // .config(moduleConfig);

  /* @ngInject */
  // function moduleConfig($stateProvider, searchTerms) {
  //   $stateProvider
  //     .state('main.search-old', {
  //       'url': '^/search-old?' + searchTerms.join('&'),
  //       'params': window._.reduce(searchTerms, function (obj, key) {
  //         obj[key] = {
  //           'value': null,
  //           'dynamic': true
  //         };
  //         return obj;
  //       }, {}),
  //       component: 'searchComponent',
  //       data: {
  //         label: 'Search',
  //         availableFor: ['regularUser', 'admin'],
  //       }
  //     });
  // }
})();
