(function () {
  angular.module('kmi.lms.user.common').component('userAddressInput', {
    template: require('ajs/modules/user/components/user-address-input.html').default,
    controller: UserAddressInputController,
    controllerAs: 'vm',
    bindings: {
      user: '=',
      addressPattern: '<?',
      formSubmitted: '=',
    },
  });

  function UserAddressInputController() {
    const vm = this;

    vm.$onInit = onInit;
    function onInit() {}
  }
})();
