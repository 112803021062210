(function () {
  angular.module('kmi.lms.user.common').factory('UserNetworkSettings', UserNetworkSettings);

  /* @ngInject */
  function UserNetworkSettings($resource, $cacheFactory) {
    var jobRolesCache = $cacheFactory('jobRoles');

    return $resource(
      '/a/user/user_network/:userId/settings/',
      { userId: '@userId' },
      {
        getJobRoles: {
          method: 'GET',
          url: '/a/user/job_roles/',
          cache: jobRolesCache,
          isArray: true,
        },
      },
      { stripTrailingSlashes: false },
    );
  }
})();
