(function () {
  angular.module('kmi.lms.user.components').component('resetPasswordModalComponent', {
    template: require('ajs/modules/user/edit/components/modal/reset-password-modal.html').default,
    controller: ResetPasswordModalController,
    controllerAs: 'vm',
    bindings: {
      resolve: '=',
      modalInstance: '<',
    },
  });

  /* @ngInject */
  function ResetPasswordModalController(serverErrorHandlerService) {
    var vm = this;
    vm.$onInit = onInit;

    vm.submit = resetPassword;
    vm.cancel = cancelModal;

    function onInit() {
      vm.user = vm.resolve.user;
    }

    function resetPassword() {
      vm.resetPasswordPromise = vm.user
        .doResetPassword()
        .then(
          function () {
            vm.modalInstance.close();
          },
          function (reason) {
            return serverErrorHandlerService.handleForbiddenError(reason, function (data) {
              vm.error = data;
            });
          },
        )
        .finally(function () {
          vm.resetPasswordPromise = null;
        });
    }

    function cancelModal() {
      vm.modalInstance.dismiss('cancel');
    }
  }
})();
