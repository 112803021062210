(function () {
  angular.module('kmi.lms.user.common').component('userZipCodeInput', {
    template: require('ajs/modules/user/components/user-zip-code-input.html').default,
    controller: UserZipCodeInputController,
    controllerAs: 'vm',
    bindings: {
      user: '=',
      zipPattern: '=',
      formSubmitted: '=',
      selfEdit: '=?',
    },
  });

  function UserZipCodeInputController(settingsService) {
    const vm = this;

    vm.$onInit = onInit;
    function onInit() {
      vm.selfEdit = vm.selfEdit !== undefined ? vm.selfEdit : true;

      settingsService
        .getSetting([
          'Users.CountryRequired',
          'Users.EmailRequired',
          'Users.StateRequired',
          'Users.ZipRequired',
          'Users.CityRequired',
          'Users.TelephoneRequired',
          'Users.AddressRequired',
        ])
        .then(function (settings) {
          vm.settings = settings;
        });
    }
  }
})();
