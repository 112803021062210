import { Subject } from 'rxjs';
import { UserRegistrationService } from './common/user-registration.service';

const angular = (<any>window).angular;

(function () {
  angular.module('kmi.lms.user.registration').component('userRegistration', {
    template: require('ajs/modules/user/registration/user-registration.html').default,
    controller: UserSelfRegistrationController,
    controllerAs: 'vm',
  });

  /* @ngInject */
  function UserSelfRegistrationController(
    $scope,
    _,
    User,
    elmsAuthService,
    notificationService,
    urlVersionService,
    backUrlService,
    navigationService,
    serverErrorHandlerService,
    userTimeZonesService,
    globalConfig,
    userRegistrationService: UserRegistrationService,
    rootScopeService,
    $state,
    siteSettingService,
    settingsService,
    $http,
    $interval,
  ) {
    var vm = this;

    vm.signInWording = globalConfig.signInWording;

    vm.backUrl = backUrlService;
    vm.currentStep = null;
    vm.availableSteps = null;

    vm.$onInit = onInit;
    vm.nextStep = nextStep;
    vm.prevStep = prevStep;
    vm.submit = submit;
    vm.groupsChangeSubject = new Subject();
    vm.changeStepDisabled = () => userRegistrationService.changeStepDisabled();

    function onInit() {
      vm.availableSteps = userRegistrationService.getAvailableSteps();
      vm.currentStep = userRegistrationService.get();

      vm.user = new User({
        loginName: '',
        password: '',
        firstName: '',
        lastName: '',
        email: '',
        policiesSignOff: false,
        receiveEmails: true,
        stayInformed: true,
        annualNotification: false,
        country: 'US',
        stateId: 5, // default state (N/A) for US
        timeZoneId: 1,
        attributes: {},
      });

      navigationService.clearTargetState();
      userTimeZonesService.populateDefaultTimeZone(vm.user);

      initRegistrationCode();
    }

    function nextStep() {
      vm.currentStep.options.submitted = true;

      if (
        vm.currentStep.formGroup.$valid &&
        (vm.currentStep.id !== vm.availableSteps[0].id || vm.policiesForm.$valid)
      ) {
        notificationService.hideError();

        vm.currentStep = userRegistrationService.next();
      } else {
        notificationService.error('Please fill in all required fields', 2e3);
      }
    }

    function prevStep() {
      notificationService.hideError();
      vm.currentStep = userRegistrationService.prev();
    }

    function submit() {
      vm.currentStep.options.submitted = true;

      vm.validationPending = true;

      let validationPending = $interval(function () {
        if (!vm.userRegistrationForm.$pending) {
          if (!$http.pendingRequests.length) {
            $interval.cancel(validationPending);

            if (vm.userRegistrationForm.$valid) {
              var userDraft = angular.copy(vm.user);
              $scope.$broadcast('event:customAttributes.saving', userDraft);

              vm.currentStep.options.pending = userDraft
                .save()
                .then(
                  function () {
                    urlVersionService.update();

                    $scope.$on('elmsAuthService.notification.login', function () {
                      settingsService.getSetting(['EmailOptIn.MandatoryEmailConfirmation']).then(function (settings) {
                        const mandatoryEmailConfirmation = settings['EmailOptIn.MandatoryEmailConfirmation'];
                        if (mandatoryEmailConfirmation === 'true') {
                          $state.go('prompt.accountCreated');
                        } else {
                          navigationService.transitionToTargetState();
                        }
                      });
                    });

                    elmsAuthService.login({
                      username: vm.user.loginName,
                      password: vm.user.password,
                    });
                  },
                  function (response) {
                    $scope.$broadcast('event:scrollTo');

                    if (response && response.data && response.data.code === 'CORP_LIC_CODE_INVALID') {
                      return notificationService.error('Please enter a valid Registration Code.', 5e3);
                    }

                    if (response && response.data && response.data.code === 'PASSWORD_RULES_FAILED') {
                      rootScopeService.broadcast('event:passwordRulesStatusUpdated', response.data.context);
                    }

                    serverErrorHandlerService.handleForbiddenError(response);
                  },
                )
                .finally(function () {
                  vm.loading = null;
                  vm.currentStep.options.pending = null;
                });
            } else {
              notificationService.error('Please fill in all required fields', 2e3);
            }

            vm.validationPending = false;
          }
        }
      });
    }

    function initRegistrationCode() {
      const siteSetting = siteSettingService.getSiteSetting();
      vm.registrationCode = !!siteSetting.requestRegistrationCode;

      if (vm.registrationCode) {
        $scope.$watch('vm.user.corporateLicenseCode', function (oldValue, newValue) {
          if (oldValue !== newValue && vm.user.corporateLicenseCode) {
            vm.groupsChangeSubject.next(vm.user);
          }
        });
      }
    }
  }
})();
