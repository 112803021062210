(function () {
  angular.module('kmi.lms.user.learning').directive('modernRecentCoursesTiles', modernRecentCoursesTiles);

  function modernRecentCoursesTiles() {
    return {
      restrict: 'EA',
      template: require('ajs/modules/user/learning/recent_courses/modern-recent-courses.html').default,
      scope: {
        maxCount: '=',
        coursesCount: '=',
        trackingName: '<',
        includeOptional: '=?',
        verticalView: '<?',
        title: '@?',
      },
      controller: RecentCoursesController,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  function RecentCoursesController(
    $scope,
    Course,
    userCoursesService,
    courseRegistrationService,
    courseComponentsService,
    elms,
    $window,
    courseEnums,
    _,
  ) {
    const vm = this;

    vm.isExists = false;
    vm.$onInit = activate;
    vm.format = courseEnums.format;

    let window = null;

    $scope.$on('event:course.registration.action.finished', function () {
      activate();
    });

    function activate() {
      if (vm.verticalView) {
        vm.verticalView = true;
      } else {
        window = angular.element($window);

        checkMobileView();

        window.bind('resize', checkMobileView);
      }

      function checkMobileView() {
        vm.verticalView = window.width() < 768;
      }

      vm.tracking_name = 'recent_courses';

      userCoursesService.getRecentCourses(vm.maxCount || 5).then(function (courses) {
        var recentCollections = [];

        vm.recentCourses = _.map(courses, function (item) {
          if (item.course.formatTypeId === courseEnums.formatType.collection) {
            recentCollections.push(item.course.id);
          }

          return {
            course: new Course(item.course),
            lastAccessedDate: item.lastAccessedDate,
          };
        });

        _.each(recentCollections, function (courseId) {
          courseComponentsService.getCompilationStructure(courseId).then(function (data) {
            _.find(vm.recentCourses, { course: { id: courseId } }).collectionStat =
              courseRegistrationService.calculateCollectionProgress(
                {
                  items: _.get(data, 'structure') || [],
                  type: 2,
                  required: true,
                },
                vm.includeOptional,
              );
          });
        });

        vm.coursesCount = vm.recentCourses.length;
      });
    }
  }
})();
