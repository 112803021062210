(function () {
  angular.module('kmi.lms.subjectAreas').directive('courseSubjectAreas', function () {
    return {
      restrict: 'EA',
      scope: {
        selectedSubjectAreas: '=',
        selectedCategories: '=?',
        subjectAreas: '<',
        formOptions: '=',
        consistentView: '<',
        showTitle: '<',
        maxCount: '<?',
        areasOptional: '<?',
      },
      template: require('ajs/modules/subject-areas/subject-areas.html').default,
      controller: EditCourseSubjectAreasCtrl,
      controllerAs: 'vm',
      bindToController: true,
    };
  });

  /* @ngInject */
  function EditCourseSubjectAreasCtrl($scope, _) {
    var vm = this,
      flattenTopics;

    vm.isSubjectAreaDisabled = isSubjectAreaDisabled;
    vm.someSelected = someSelected;
    vm.expandSubjectArea = expandSubjectArea;

    vm.$onInit = onInit;

    function onInit() {
      //vm.subjectAreas = angular.copy(vm.subjectAreas);

      vm.selectedSubjectAreas = vm.selectedSubjectAreas || [];
      vm.selectedCategories = vm.selectedCategories || [];

      flattenTopics = _.flatten(
        vm.subjectAreas.map(function (area) {
          return area.topics;
        }),
      );

      var areaIds = _.map(vm.selectedCategories, 'id');
      var topicIds = _.map(vm.selectedSubjectAreas, 'id');

      vm.subjectAreas.forEach(function (area) {
        if (_.includes(areaIds, area.id)) {
          area.selected = true;
        }
      });

      flattenTopics.forEach(function (topic) {
        topic.selected = _.includes(topicIds, topic.id);
      });

      vm.subjectAreas.forEach(function (area) {
        $scope.$watch(
          function () {
            return area.selected;
          },
          function (newValue, oldValue) {
            if (newValue !== oldValue) {
              if (area.selected !== 0) {
                area.topics.forEach(function (topic) {
                  topic.selected = area.selected;
                });
              }

              vm.selectedCategories = _.filter(vm.subjectAreas, 'selected');
            }
          },
        );

        $scope.$watch(
          function () {
            return area.topics;
          },
          function () {
            var selectedTopics = _.filter(area.topics, 'selected');
            area.selected =
              selectedTopics.length > 0 ? (selectedTopics.length === area.topics.length ? true : 0) : false;

            if (!area.expanded) {
              area.expanded = selectedTopics.length > 0;
            }

            vm.selectedSubjectAreas = _.filter(flattenTopics, 'selected');
          },
          true,
        );
      });
    }

    function isSubjectAreaDisabled(subjectArea) {
      if (subjectArea.selected || !vm.maxCount) {
        return false;
      }

      var unselectedTopicsCount = _.difference(
        _.map(subjectArea.topics, 'id'),
        _.map(vm.selectedSubjectAreas, 'id'),
      ).length;

      return vm.maxCount - vm.selectedSubjectAreas.length < unselectedTopicsCount;
    }

    function someSelected() {
      return vm.selectedSubjectAreas && vm.selectedSubjectAreas.length > 0;
    }

    function expandSubjectArea(subjectArea) {
      subjectArea.expanded = !subjectArea.expanded;
    }
  }
})();
