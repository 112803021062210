(function () {
  /**
   * @ngdoc service
   * @name kmi.lms.user.mail.service:userEmailOptInService
   *
   *
   * @description
   * Provide OptIn functionality
   * Implements all server's OptIn methods.
   */

  angular.module('kmi.lms.user.mail').factory('userEmailOptInService', userEmailOptInService);

  /* @ngInject */
  function userEmailOptInService($http, $uibModal, apiUrl) {
    var baseApiUrl = '/a/user/opt_in/';

    return {
      optInAction: optInAction,
      optOutAction: optOutAction,
      resendAction: resendAction,
      showOptInModal: showOptInModal,
    };

    function optInAction() {
      return doAction('optIn');
    }

    function optOutAction() {
      return doAction('optOut');
    }

    function resendAction() {
      return doAction('resend');
    }

    function doAction(action) {
      return $http.post(apiUrl(baseApiUrl), {
        action: action,
      });
    }

    function showOptInModal(email) {
      var modalInstance = $uibModal.open({
        component: 'userEmailOptInModalComponent',
        keyboard: false,
        resolve: {
          userEmail: function () {
            return email;
          },
        },
      });

      return modalInstance.result;
    }
  }
})();
