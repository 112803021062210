(function () {
  angular.module('kmi.lms.user.registration').component('userInfoRegistration', {
    template: require('ajs/modules/user/registration/components/user-info-registration.html').default,
    controller: UserInfoRegistration,
    controllerAs: 'vm',
    bindings: {
      step: '=',
      user: '<',
      registrationCode: '<?',
    },
  });

  /* @ngInject */
  function UserInfoRegistration($scope, regexPatterns, globalConfig) {
    var vm = this;

    vm.$onInit = onInit;
    vm.settings = globalConfig.settings;

    function onInit() {
      if (!vm.user.stateInited) {
        vm.user.stateId = null;
        vm.user.stateInited = true;
      }

      $scope.$watch('vm.user.country', function () {
        if (vm.user.country === 'US') {
          vm.zipPattern = regexPatterns.usZip;
        } else {
          vm.zipPattern = regexPatterns.internationalZip;
        }
      });
    }
  }
})();
