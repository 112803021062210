(function () {
  angular
    .module('kmi.lms.user.learning', [
      'ui.router',
      'kmi.lms.core',
      'kmi.lms.components',
      'kmi.lms.course.registrations',
      'kmi.lms.course.common',
      'kmi.lms.user.certificates',
      'globalDependencies',
      'ui.bootstrap',
    ])
    .constant('mainLearningDashboardState', {
      url: '^/dashboard?card',
      component: 'userLearningDashboard',
      data: {
        label: 'Your Learning',
        availableFor: ['regularUser', 'admin'],
      },
      params: {
        card: {
          dynamic: true,
          value: null,
        },
      },
      resolve: {
        user: function (currentUser) {
          return currentUser.get();
        },
      },
      onEnter: function ($transition$, $state) {
        if ($transition$.params().card) {
          $state.go('main.learningDashboardCard', { selected: $transition$.params().card });
        }
      },
    })
    .config(moduleConfig);

  /* @ngInject*/
  function moduleConfig($stateProvider) {
    $stateProvider
      .state('main.learningDashboardCard', {
        url: '^/dashboard-card?selected&sort&dir&showWithdrawn&page',
        component: 'userLearningDashboardCard',
        data: {
          label: 'Dashboard Card',
          availableFor: ['regularUser', 'admin'],
        },
        params: {
          sort: {
            value: null,
            dynamic: true,
          },
          dir: {
            value: null,
            dynamic: true,
          },
          selected: {
            value: null,
            dynamic: true,
          },
          showWithdrawn: {
            value: null,
            dynamic: true,
          },
          page: {
            value: null,
            dynamic: true,
          },
        },
        resolve: {
          user: function (currentUser) {
            return currentUser.get();
          },
        },
      })
      .state('edit.externalCourse', {
        url: '/external_course/:courseId',
        params: {
          courseId: null,
        },
        data: {
          label: 'External course',
          availableFor: ['regularUser', 'admin'],
        },
        component: 'editExternalCourseComponent',
        resolve: {
          course: function ($transition$, ExternalCourse, currentUser) {
            if ($transition$.params().courseId) {
              return ExternalCourse.get({ userId: currentUser.get().id, courseId: $transition$.params().courseId })
                .$promise;
            } else {
              return new ExternalCourse({ userId: currentUser.get().id, statusId: 5 });
            }
          },
        },
      });
  }
})();
