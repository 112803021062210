(function () {
  angular.module('kmi.lms.user.common').factory('userRequirementsService', userRequirementsService);

  /* @ngInject */
  function userRequirementsService($http, apiUrl) {
    var baseUrl = '/a/user/me/requirements';

    return {
      check: check,
    };

    function check(requirementName, params) {
      var url = requirementName ? [baseUrl, requirementName].join('/') : baseUrl;
      return $http.get(apiUrl(url), { params: params }).then(function (response) {
        return response.data;
      });
    }
  }
})();
