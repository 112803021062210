(function () {
  angular.module('kmi.lms.quiz').directive('quizResultView', quizResultView);

  /* @ngInject */
  function quizResultView() {
    return {
      restrict: 'EA',
      scope: {
        result: '=',
      },
      template: require('ajs/modules/quiz/results/modern/results-view.html').default,
      controller: QuizResultViewController,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  /* @ngInject */
  function QuizResultViewController($uibModal, quizQuestionEnums, elms, _) {
    var vm = this;

    vm.quizQuestionType = quizQuestionEnums.questionType;
    vm.questionTypeExtension = quizQuestionEnums.questionTypeExtension;
    vm.formatDigit = elms.formatDigit;

    vm.getCorrectChoices = getCorrectChoices;
    vm.getSelectedChoices = getSelectedChoices;
    vm.showQuestionCorrectness = showQuestionCorrectness;
    vm.showCorrectAnswers = showCorrectAnswers;
    vm.$onInit = onInit;

    function onInit() {
      vm.viewedQuestions = [];
      for (var i = 0; i < vm.result.questions.length; i++) {
        vm.viewedQuestions.push(vm.result.questions[i]);

        var selectedAnswerVariants = null;
        if (vm.result.questions[i].answerVariants) {
          selectedAnswerVariants = _.filter(vm.result.questions[i].answerVariants, 'selected');
        }

        if (selectedAnswerVariants && selectedAnswerVariants.length === 1 && selectedAnswerVariants[0].nextQuestionId) {
          var index = _.indexOf(_.map(vm.result.questions, 'questionId'), selectedAnswerVariants[0].nextQuestionId) - 1;
          if (index >= i) {
            i = index;
          }
        }
      }
    }

    function showQuestionCorrectness(question) {
      return (
        vm.result &&
        vm.result.quiz &&
        vm.result.isCompleted() &&
        question.typeId !== vm.quizQuestionType.shortAnswer &&
        question.typeExtensionId !== vm.questionTypeExtension.likertScale.id &&
        (vm.result.quiz.showQuestionCorrectness === 1 ||
          (vm.result.quiz.showQuestionCorrectness === 2 &&
            (vm.result.attempt === vm.result.quiz.attemptsAllowed || _.includes([2, 3], vm.result.statusId))))
      );
    }

    function showCorrectAnswers(question) {
      return (
        vm.result &&
        vm.result.quiz &&
        vm.result.isCompleted() &&
        showQuestionCorrectness(question) &&
        question.typeId !== vm.quizQuestionType.shortAnswer &&
        (vm.result.quiz.showCorrectAnswers === 1 ||
          (vm.result.quiz.showCorrectAnswers === 2 &&
            (vm.result.attempt === vm.result.quiz.attemptsAllowed || _.includes([2, 3], vm.result.statusId))))
      );
    }

    /**
     * @description
     * Returns question correct choices
     * @param question
     * @returns {*}
     */
    function getCorrectChoices(question) {
      if (question.answerVariants) {
        if (vm.quizQuestionType.fillin === question.typeId) {
          return question.answerVariants;
        } else {
          return _.filter(question.answerVariants, 'correct');
        }
      }

      return null;
    }

    /**
     * @description
     * Returns selected question choices
     * @param question
     * @returns {*}
     */
    function getSelectedChoices(question) {
      if (question.answerVariants) {
        if (vm.quizQuestionType.fillin === question.typeId) {
          return question.answerVariants;
        } else {
          return _.filter(question.answerVariants, 'selected');
        }
      }

      return null;
    }
  }
})();
