(function () {
  angular.module('kmi.lms.quiz').factory('quizTemporaryFilesService', quizTemporaryFilesService);

  function quizTemporaryFilesService() {
    var temporaryFiles = {};

    return {
      storeFile: storeFile,
      getFiles: getStoredFiles,
      clearFiles: clearFiles,
    };

    function storeFile(fileObj) {
      temporaryFiles[fileObj.tmpName] = fileObj;
    }

    function getStoredFiles() {
      return temporaryFiles;
    }

    function clearFiles() {
      temporaryFiles = {};
    }
  }
})();
