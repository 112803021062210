(function () {
  angular.module('kmi.lms.user.common').component('userFirstNameInput', {
    template: require('ajs/modules/user/components/user-firstname-input.html').default,
    controller: UserFirstNameInputController,
    controllerAs: 'vm',
    bindings: {
      user: '=',
      formSubmitted: '=',
    },
  });

  function UserFirstNameInputController() {
    const vm = this;

    vm.$onInit = onInit;
    function onInit() {}
  }
})();
