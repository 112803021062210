(function () {
  angular.module('kmi.lms.user.upcomingEvents').component('userUpcomingEventsTile', {
    restrict: 'AE',
    replace: true,
    bindings: {
      count: '=?eventsCount',
    },
    template: require('ajs/modules/user/upcoming-events/tile/template.html').default,
    controller: UserUpcomingEventsTileController,
    controllerAs: 'vm',
    bindToController: true,
  });

  /* @ngInject */
  function UserUpcomingEventsTileController($scope, $http, apiUrl, currentUser, notificationService) {
    var vm = this,
      events;

    vm.loaded = 0;
    vm.error = null;

    vm.$onInit = onInit;

    //////////
    function onInit() {
      vm.count = 0;
      activate();
    }

    function activate() {
      $scope.$watch('vm.currentIndex', function () {
        vm.currentEvent = events && events[vm.currentIndex] ? events[vm.currentIndex] : null;
      });

      $http
        .get(apiUrl('/a/user/' + currentUser.get().id + '/upcoming_events/'))
        .then(function (res) {
          events = res.data;
          vm.currentIndex = 0;
          vm.currentEvent = events.length ? events[0] : null;
          vm.count = events.length;
        })
        .catch(function (error) {
          vm.error = error;
          notificationService.error(error.data, 5e3);
        })
        .finally(function () {
          vm.loaded = 1;
        });
    }
  }
})();
