(function () {
  angular.module('kmi.lms.user.common').component('userCountrySelection', {
    template: require('ajs/modules/user/components/user-country-selection.html').default,
    controller: UserCountrySelectionController,
    controllerAs: 'vm',
    bindings: {
      user: '=',
      selfEdit: '<',
      settings: '=',
      countryChangeHandler: '&onCountryChange',
      formSubmitted: '=',
    },
  });

  function UserCountrySelectionController(geoService) {
    const vm = this;

    vm.isCountryRequired = isCountryRequired;
    vm.countryChange = countryChange;

    vm.$onInit = onInit;

    function onInit() {
      geoService.getCountries(true).toPromise().then(bindCountries);
    }

    function bindCountries(countries) {
      vm.countries = countries;
    }

    function isCountryRequired() {
      return (
        vm.selfEdit &&
        vm.settings &&
        vm.settings['Users.CountryRequired'] &&
        vm.settings['Users.CountryRequired'].toLowerCase() === 'true'
      );
    }

    function countryChange() {
      vm.user.stateId = null;
      vm.user.state = null;
      vm.user.stateName = null;
    }
  }
})();
