(function () {
  angular
    .module('kmi.lms.user.certificates', [
      'ui.bootstrap',
      'kmi.lms.core',
      'kmi.lms.course.registrations',
      'kmi.lms.components',
    ])
    .config(moduleConfig);

  /* @ngInject*/
  function moduleConfig($stateProvider, globalConfig) {
    $stateProvider
      .state('edit.externalCertificate', {
        url: '/external_certificate/{certificateId:int}',
        params: {
          certificateId: null,
        },
        data: {
          label: 'External certificate',
          availableFor: ['regularUser', 'admin'],
        },
        component: 'editExternalCertificateComponent',
        resolve: {
          certificate: function ($transition$, ExternalCertificate, currentUser) {
            return ExternalCertificate.get({
              userId: currentUser.get().id,
              certificateId: $transition$.params().certificateId,
            }).$promise;
          },
        },
      })
      .state('edit.nonElmsRegistrationExternalCertificate', {
        url: '/non_elms_course/:courseId/external_certificate',
        params: {
          courseId: null,
        },
        component: 'editExternalCertificateComponent',
        data: {
          label: 'External certificate',
          availableFor: ['regularUser', 'admin'],
        },
        resolve: {
          certificate: function ($transition$, ExternalCertificate, currentUser) {
            return ExternalCertificate.getByRegistration({
              userId: currentUser.get().id,
              registrationId: $transition$.params().courseId,
              non_elms: true,
            }).$promise.then(function (certificate) {
              if (!certificate.id) {
                return ExternalCertificate.newCertificate({
                  userId: currentUser.get().id,
                  registrationId: $transition$.params().courseId,
                  nonElms: true,
                });
              }

              return certificate;
            });
          },
        },
      });

    if (globalConfig.settings.view !== 'admin') {
      $stateProvider.state('edit.registrationExternalCertificate', {
        url: '/registrations/:registrationId/external_certificate',
        params: {
          registrationId: null,
        },
        data: {
          label: 'External certificate',
          availableFor: ['regularUser', 'admin'],
        },
        component: 'editExternalCertificateComponent',
        resolve: {
          certificate: function ($transition$, ExternalCertificate, currentUser) {
            return ExternalCertificate.getByRegistration({
              userId: currentUser.get().id,
              registrationId: $transition$.params().registrationId,
              non_elms: false,
            }).$promise.then(function (certificate) {
              if (!certificate.id) {
                return ExternalCertificate.newCertificate({
                  userId: currentUser.get().id,
                  registrationId: $transition$.params().registrationId,
                  nonElms: false,
                });
              }

              return certificate;
            });
          },
        },
      });
    }
  }
})();
