(function () {
  angular.module('kmi.lms.user.learning').component('userMiniDashboard', {
    template: require('ajs/modules/user/learning/dashboard/mini-dashboard.html').default,
    controller: UserMiniDashboardController,
    controllerAs: 'vm',
    bindings: {
      user: '<',
    },
  });

  /* @ngInject */
  function UserMiniDashboardController(userLearningDashboardService, globalConfig, currentUser, _) {
    var vm = this;

    vm.dashboardCards = globalConfig.settings.userDashboard.cards;
    vm.$onInit = onInit;

    function onInit() {
      vm.allCards = _.filter(_.flatten(_.map(vm.dashboardCards, 'cards')), 'showInMiniDashboard');
      vm.user = vm.user || currentUser.get();

      userLearningDashboardService
        .getDashboard(vm.user.id, { cards: _.map(vm.allCards, 'name') })
        .then(function (dashboardData) {
          vm.dashboardData = dashboardData;
        });
    }
  }
})();
