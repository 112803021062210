(function () {
  angular.module('kmi.lms.user.common').factory('UserGroup', userGroup);

  /* @ngInject */
  function userGroup($resource) {
    return $resource(
      '/a/user/:userId/groups/:groupId/',
      { userId: '@userId', groupId: '@groupId' },
      {
        query: {
          method: 'GET',
          isArray: true,
        },
        save: {
          method: 'POST',
          isArray: true,
        },
        delete: {
          method: 'DELETE',
        },
      },
      {
        stripTrailingSlashes: false,
      },
    );
  }
})();
