(function () {
  angular.module('kmi.lms.user.certificates').component('deleteExternalCertificateModalComponent', {
    template: require('ajs/modules/user/certificates/external/modal/delete-external-certificate.html').default,
    controller: DeleteExternalCertificateModalController,
    controllerAs: 'vm',
    bindings: {
      resolve: '=',
      modalInstance: '=',
    },
  });

  /* @ngInject */
  function DeleteExternalCertificateModalController(serverErrorHandlerService) {
    var vm = this;
    vm.$onInit = onInit;

    vm.submit = submit;
    vm.cancel = cancel;

    function onInit() {
      vm.certificate = vm.resolve.certificate;
    }

    function submit() {
      vm.deletionProgress = true;

      vm.certificate
        .$delete(
          function () {
            vm.modalInstance.close();
          },
          function (response) {
            return serverErrorHandlerService
              .handleForbiddenError(response, function (message) {
                vm.deleteError = message;
              })
              .catch(function (error) {
                if (error) {
                  vm.deleteError = 'An error occurred during certificate deletion. Please try again later.';
                }
              });
          },
        )
        .finally(function () {
          vm.deletionProgress = null;
        });
    }

    function cancel() {
      vm.modalInstance.dismiss('cancel');
    }
  }
})();
