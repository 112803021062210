(function () {
  angular.module('kmi.lms.user.notifications').component('userNotificationsGroupedModalComponent', {
    template: require('ajs/modules/user/notifications/grouped/modal/notifications.html').default,
    controller: UserNotificationsGroupedModalController,
    controllerAs: 'vm',
    bindings: {
      resolve: '=',
      modalInstance: '=',
    },
  });

  /* @ngInject */
  function UserNotificationsGroupedModalController() {
    var vm = this;
    vm.$onInit = onInit;
    vm.onView = onView;

    function onInit() {
      vm.cancel = vm.modalInstance.dismiss;
      vm.notifications = vm.resolve.notifications;
    }

    function onView() {
      vm.modalInstance.close();
    }
  }
})();
