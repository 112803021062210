(function () {
  angular.module('kmi.lms.search.common').service('searchFilterObject', searchFilterObject);

  /* @ngInject */
  function searchFilterObject(searchService, _, globalConfig) {
    var service = {
      getOptions: getOptions,
    };
    return service;

    ////////////

    function getOptions() {
      return {
        label: 'Object Type',
        term: 'type',
        mode: 'singleChoice',
        open: true,
        items: [],
        load: function () {
          var mode = globalConfig.settings.view === 'admin' ? 'admin' : 'public';

          return searchService
            .get({ mode: mode })
            .getObjectTypes()
            .then(
              function (objectTypes) {
                this.items = _.map(objectTypes, function (item) {
                  return {
                    text: item.name,
                    value: item.value,
                  };
                });

                this._initSelectedItems();
              }.bind(this),
            );
        },
      };
    }
  }
})();
