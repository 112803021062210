(function () {
  angular.module('kmi.lms.search.common').service('searchFilterCourseQuality', searchFilterCourseQuality);

  /* @ngInject */
  function searchFilterCourseQuality(customCourseAttributesService) {
    return {
      getCourseQualityFilterConfig: getCourseQualityFilterConfig,
    };

    function getCourseQualityFilterConfig() {
      return {
        getOptions: getOptions,
      };

      function getOptions(options) {
        return angular.extend(
          {
            term: 'quality',
            label: 'Meets CDC Quality Training Standards',
            mode: 'booleanFilter',
            items: [],
            dependency: [
              {
                condition: 'course',
              },
              function () {
                return this.items.length > 0;
              },
            ],
            load: function () {
              return customCourseAttributesService.getCategories({ categoryId: [130] }, true).then(
                function (response) {
                  if (response.data.length) {
                    this.items.push({ text: 'Quality Training', value: 'cdc' });
                  }

                  this._initSelectedItems();
                }.bind(this),
              );
            },
          },
          options,
        );
      }
    }
  }
})();
