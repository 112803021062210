(function () {
  /**
   * @ngdoc service
   * @name kmi.lms.quiz.service:QuizQuestion
   * @description
   * Represents QuizQuestion data model.
   */

  angular.module('kmi.lms.quiz').factory('QuizQuestion', QuizQuestion);

  /* @ngInject */
  function QuizQuestion($resource, uuid) {
    var Question = $resource(
      '/a/quizzes/questions/:questionId/',
      { questionId: '@id' },
      {
        get: {
          method: 'GET',
          transformResponse: transformResponse,
        },
        save: {
          method: 'POST',
          params: {
            weight: '@weight',
          },
        },
        update: { method: 'PUT' },
      },
      {
        stripTrailingSlashes: false,
      },
    );

    function transformResponse(data, headers, status) {
      if (status === 200) {
        var jsonData = angular.fromJson(data);
        jsonData = new Question(angular.extend(Question.defaults, jsonData));
        return jsonData;
      }
      if (headers('ContentType') === 'application/json') {
        return angular.fromJson(data);
      }
      return data;
    }

    Object.defineProperty(Question, 'defaults', {
      get: function () {
        return {
          globalId: uuid.v4(),
          attachments: [],
          active: true,
          answerVariants: [],
          optional: false,
        };
      },
    });

    Question.newQuestion = function (attrs) {
      return new Question(angular.extend(Question.defaults, attrs || {}));
    };

    return Question;
  }
})();
