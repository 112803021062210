(function () {
  angular.module('kmi.lms.user.certificates').component('userCertificates', {
    template: require('ajs/modules/user/certificates/certificates.html').default,
    controller: UserCertificatesController,
    controllerAs: 'vm',
  });

  /* @ngInject */
  function UserCertificatesController(
    $location,
    userCertificatesService,
    serverErrorHandlerService,
    currentUser,
    elms,
    _,
  ) {
    var vm = this;
    vm.$onInit = onInit;

    vm.predicate = 'title';
    vm.reverse = false;

    vm.sortOrder = sortOrder;
    vm.isCurrentSortPredicate = isCurrentSortPredicate;
    vm.printCertificate = userCertificatesService.printCertificate;
    vm.formatDigit = elms.formatDigit;

    function onInit() {
      var searchQuery = $location.search();

      if (!searchQuery.filter) {
        vm.predicate = 'title';
        vm.reverse = false;
      } else if (searchQuery.filter) {
        vm.predicate = searchQuery.filter;
        vm.reverse = searchQuery.reverse === 'true';
      }

      var cachedModel = JSON.parse(sessionStorage.getItem('userCertificates'));

      if (cachedModel !== null) {
        vm.certificates = cachedModel.certificates;
      }

      bindData();
    }

    function bindData() {
      vm.loading = userCertificatesService
        .getCertificates(currentUser.get().id)
        .then(
          function (certificatesData) {
            vm.certificates = certificatesData;

            sessionStorage.setItem(
              'userCertificates',
              JSON.stringify({
                certificates: vm.certificates,
              }),
            );
          },
          function (reason) {
            serverErrorHandlerService.handleForbiddenError(reason, function (errorMessage) {
              vm.error = errorMessage;
            });
          },
        )
        .finally(function () {
          vm.loading = null;
        });
    }

    function sortOrder(predicate) {
      vm.reverse = _.isEqual(vm.predicate, predicate) ? !vm.reverse : false;
      vm.predicate = predicate;
      $location.search(
        angular.extend({}, $location.search(), {
          filter: vm.predicate,
          reverse: vm.reverse.toString(),
        }),
      );
    }

    function isCurrentSortPredicate(predicate) {
      return _.isEqual(vm.predicate, predicate);
    }
  }
})();
