(function () {
  angular.module('kmi.lms.user.common').component('userPasswordConfirmationInput', {
    template: require('ajs/modules/user/components/user-password-confirmation-input.html').default,
    controller: UserPasswordConfirmationInputController,
    controllerAs: 'vm',
    bindings: {
      user: '=',
      formSubmitted: '=',
    },
  });

  function UserPasswordConfirmationInputController() {
    const vm = this;

    vm.$onInit = onInit;
    function onInit() {}
  }
})();
