import { debounceTime } from 'rxjs';

const angular = (<any>window).angular;

(function () {
  angular.module('kmi.lms.user.registration').component('userAttributesRegistration', {
    template: require('./user-attributes-registration.html').default,
    controller: UserAttributesRegistrationController,
    controllerAs: 'vm',
    bindings: {
      step: '<',
      user: '<',
      groupsChangeSubject: '<',
    },
  });

  /* @ngInject */
  function UserAttributesRegistrationController(
    $scope,
    customUserAttributesService,
    serverErrorHandlerService,
    attributesService,
    groupService,
    Group,
    $q,
  ) {
    var vm = this;

    vm.$onInit = onInit;
    vm.initForm = attributesService.initForm;

    function onInit() {
      vm.loading = true;
      loadAttributes(vm.user);

      $scope.$on('event:customAttributes.saving', function (event, user) {
        user.attributes = [];

        attributesService.prepareAttributesForSave(user, vm.attributeCategories);
      });

      vm.subscriber = vm.groupsChangeSubject.pipe(debounceTime(700)).subscribe(function (user) {
        loadAttributes(user);
      });
    }

    vm.$onDestroy = function () {
      vm.subscriber.unsubscribe();
    };

    function getPromiseByCorporateCode(user, groups) {
      return user.corporateLicenseCode
        ? Group.query({
            query: {
              selfReg: true,
              parent_ids: groups.map((g) => g.id),
              corporate_license_code: user.corporateLicenseCode,
            },
          }).$promise
        : $q.resolve({ items: [] });
    }

    function getPromiseDefault(user) {
      return groupService
        .getDefaultSelfRegGroups()
        .then((groups) => [...(user.groups ? user.groups : []), ...groups.map((g) => g.group)]);
    }

    function loadCategories(groupIds) {
      customUserAttributesService
        .getAttributeCategories(
          {
            withAttributes: true,
            requiredOnly: true,
            groups: angular.toJson(groupIds),
          },
          null,
          null,
          true,
        )
        .then(function (categories) {
          vm.attributeCategories = categories;
          vm.step.disabled = !vm.attributeCategories.some((category) => category.attributes.length > 0);
        })
        .catch(function (reason) {
          serverErrorHandlerService.handleForbiddenError(reason);
        })
        .finally(function () {
          vm.loading = false;
          vm.step.options.pending = false;
        });
    }

    function loadAttributes(user) {
      vm.step.options.pending = true;

      getPromiseDefault(user)
        .then((groups) => {
          return $q.all([$q.resolve(groups), getPromiseByCorporateCode(user, groups)]);
        })
        .then(function ([selfRegGroups, selfRegGroupsByCorporateCode]) {
          const groupIds = [...selfRegGroups.map((g) => g.id), ...selfRegGroupsByCorporateCode.items.map((g) => g.id)];
          if (groupIds.length) {
            loadCategories(groupIds);
          } else {
            vm.step.disabled = true;
            vm.step.options.pending = false;
            vm.loading = false;
          }
        });
    }
  }
})();
