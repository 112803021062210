(function () {
  angular.module('kmi.lms.user.certificates').factory('ExternalCertificate', externalCertificate);

  /* @ngInject */
  function externalCertificate($resource, uuid) {
    var ExternalCertificate = $resource(
      '/a/user/:userId/certificates/external/:certificateId/',
      { userId: '@userId', certificateId: '@id' },
      {
        get: {
          method: 'GET',
          transformResponse: transformResponse,
        },
        update: { method: 'PUT' },
        getByRegistration: {
          method: 'GET',
          url: '/a/user/:userId/certificates/external/registration/:registrationId/',
          params: { userId: '@userId', registrationId: '@registrationId' },
          isArray: false,
          transformResponse: transformResponse,
        },
      },
      { stripTrailingSlashes: false },
    );

    ExternalCertificate.newCertificate = function (attrs) {
      return new ExternalCertificate(angular.extend(ExternalCertificate.defaults, attrs || {}));
    };

    Object.defineProperty(ExternalCertificate, 'defaults', {
      get: function () {
        return {
          globalId: uuid.v4(),
        };
      },
    });

    return ExternalCertificate;

    function transformResponse(data, headers, status) {
      if (status === 200) {
        return data ? angular.extend(ExternalCertificate.defaults, angular.fromJson(data)) : null;
      }
      //When status is not 200 response body may contain error message or some useful data.
      if (headers('Content-Type') === 'application/json') {
        return angular.fromJson(data);
      }
      return data;
    }
  }
})();
