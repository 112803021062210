(function () {
  angular.module('kmi.lms.user.components').directive('editUserContactInfo', editUserContactInfo);

  function editUserContactInfo() {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        user: '=',
        selfEdit: '=',
        formOptions: '=',
      },
      template: require('ajs/modules/user/edit/components/contact-information.html').default,
      controller: EditUserContactInfoController,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  /* @ngInject*/
  function EditUserContactInfoController($scope, settingsService, policiesService, regexPatterns) {
    var vm = this;

    vm.$onInit = onInit;

    function onInit() {
      policiesService.getPolicies().then(function (policies) {
        vm.policies = policies;
      });

      settingsService
        .getSetting([
          'Users.CountryRequired',
          'Users.EmailRequired',
          'Users.StateRequired',
          'Users.ZipRequired',
          'Users.CityRequired',
          'Users.TelephoneRequired',
          'Users.AddressRequired',
        ])
        .then(function (settings) {
          vm.settings = settings;
          vm.telephoneRequired =
            vm.selfEdit &&
            vm.settings['Users.TelephoneRequired'] &&
            vm.settings['Users.TelephoneRequired'].toLowerCase() === 'true';
        });

      $scope.$watch('vm.user.country', function () {
        if (vm.user.country === 'US') {
          vm.zipPattern = regexPatterns.usZip;
        } else {
          vm.zipPattern = regexPatterns.internationalZip;
        }
      });
    }
  }
})();
