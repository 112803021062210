(function () {
  angular.module('kmi.lms.user.components').component('providePasswordModalComponent', {
    template: require('ajs/modules/user/edit/components/modal/provide-password-modal.html').default,
    controller: ProvidePasswordModalController,
    controllerAs: 'vm',
    bindings: {
      resolve: '=',
      modalInstance: '=',
    },
  });

  /* @ngInject */
  function ProvidePasswordModalController(rootScopeService, serverErrorHandlerService) {
    var vm = this;
    vm.$onInit = onInit;

    vm.password = null;
    vm.submit = submitPassword;
    vm.cancel = cancelModal;

    function onInit() {
      vm.user = angular.copy(vm.resolve.user);
      delete vm.user.password;
      delete vm.user.oldPassword;
    }

    function submitPassword() {
      vm.submitted = true;

      if (vm.providePasswordForm.$valid) {
        if (vm.user.id) {
          vm.changePasswordPromise = vm.user
            .changePassword({ password: vm.user.password })
            .then(
              function () {
                vm.modalInstance.close();
              },
              function (reason) {
                if (reason.status === 403 && reason.data && reason.data.code === 'PASSWORD_RULES_FAILED') {
                  rootScopeService.broadcast('event:passwordRulesStatusUpdated', reason.data.context);
                }

                return serverErrorHandlerService.handleForbiddenError(reason, function (data) {
                  vm.error = data;
                });
              },
            )
            .finally(function () {
              vm.changePasswordPromise = null;
            });
        } else {
          vm.resolve.user.password = vm.user.password;
          vm.modalInstance.close();
        }
      }
    }

    function cancelModal() {
      vm.modalInstance.dismiss('cancel');
    }
  }
})();
