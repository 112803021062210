(function () {
  angular.module('kmi.lms.user.learning').component('userLearningDashboardCardItem', {
    template: require('ajs/modules/user/learning/dashboard/dashboard-card-item.html').default,
    controller: UserLearningDashboardCardItemController,
    controllerAs: 'vm',
    bindings: {
      item: '=',
      selectedCard: '<',
      section: '<',
      credits: '<',
      totalCes: '<',
      loading: '<',
    },
  });

  /* @ngInject */
  function UserLearningDashboardCardItemController(
    $scope,
    userLearningDashboardCardSettings,
    userCertificatesService,
    userLearningDashboardService,
    $uibModal,
    globalConfig,
    $state,
    _,
  ) {
    var vm = this;

    vm.isColumnAvailable = userLearningDashboardService.isColumnAvailableForView;
    vm.appSettings = globalConfig.settings;

    vm.printCertificate = printCertificate;
    vm.$onInit = onInit;
    vm.goToDetails = goToDetails;

    function onInit() {
      vm.registrationType = _.includes(
        ['incompleteExpired', 'incomplete', 'mandatory', 'collections', 'saved', 'evaluationPending', 'dueDate'],
        vm.selectedCard.name,
      )
        ? 'lastRegistration'
        : 'lastCompletedRegistration';
    }

    /**
     * @ngdoc function
     * @name kmi.lms.user.learning.userLearningDashboardCardItem#goToDetails
     * @methodOf kmi.lms.user.learning.component: userLearningDashboardCardItemComponent
     * @description
     * Open course details page
     */
    function goToDetails(courseId) {
      if (!vm.loading) {
        $state.go('main.course', { id: courseId });
      }
    }

    /**
     * @ngdoc function
     * @name kmi.lms.user.learning.userLearningDashboardCardItem#printCertificate
     * @methodOf kmi.lms.user.learning.component: userLearningDashboardCardItemComponent
     * @description
     * Opens a dialog window with certificates details
     */
    function printCertificate(certifiedRegistration) {
      userCertificatesService.printCertificate(certifiedRegistration.id, certifiedRegistration.external);
    }
  }
})();
